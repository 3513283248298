<template>

	<div>

    <ArticleMobile 
    :key="index"
    :content="content"
    :index="index"
    :clientWidth="ClientWidth">
    </ArticleMobile>

		<div class="HomepageArticleMobileNews">

			<div class="HomepageArticleMobileNews--content" @click="newsletterClicked = !newsletterClicked">
				<div class="HomepageArticleMobileNews--content--text">{{whatContentLangTitle}}</div>
				<div class="HomepageArticleMobileNews--content--text2" :class="newsletterClicked ? 'formIsOpen' : ''">{{whatContentLang}}</div>
			</div>

			<div class="HomepageArticleMobileNews--form">
				<transition name="fade-article-news" mode="out-in">
				<Newsletter :context="'homepage'" v-if="newsletterClicked"></Newsletter>
				</transition>
			</div>
			
		</div>


	</div>
</template>

<script>
import Newsletter from '@/components/Newsletter.vue'

import ArticleMobile from '@/components/homepage/ArticleMobile.vue'


export default {
  name: 'HomepageArticleMobileNews',
  props: ['content', 'index'],
	components:{
		Newsletter,
		ArticleMobile
  },
	data: function(){
		return {
			newsletterClicked: false
		}
	},
  computed: {
		whatContentLangTitle: function(){
			if (this.$route.params.language == 'fr'){
				return 'Devenez profane'
			}else {
				return 'Join Profane'
			}
		},
		whatContentLang: function(){
			if (this.$route.params.language == 'fr'){
				return 'Inscrivez-vous à la newsletter'
			}else {
				return 'Subscribe to the newsletter'
			}
		}, 

  },
	mounted(){
		// console.log('HomepageArticleMobileNews')
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.HomepageArticleMobileNews{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	z-index: 2;

	&--text-container{
		width: 100%;
		margin: var(--esp-big);

		&--title{
			font-size: var(--home-font-case1-title);
			line-height: 0.9;
			word-break: break-word;
		}

		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case1-text);
		}
	}

	&--img-container{
		width: 100%;
		overflow: hidden;

		&--img{
			width: 100%;
			height: 100%;
			transform: scale(1.05);
		}
	}
}



.HomepageArticleMobileNews{

	cursor: pointer;

	overflow: hidden;

	width: 100%;
	height: var(--home-case-height);

	background-color: rgb(230,230,230);

	position: relative;
	margin-top: var(--esp-med);

	&--content{
		width: calc(100% - 2 * var(--esp-med));
		// height: calc(var(--home-case-height) - 2 * var(--home-height-case-newsletter) - 2 * var(--esp-med));

		height: 90%;
		margin: var(--esp-big);


		&--text{
			font-size: var(--home-font-case3-title);
			line-height: 0.9;
		}

		&--text2{
			font-size: var(--home-font-case1-title);
			line-height: 0.9;

			position: absolute;

			bottom: calc(var(--esp-big));

			vertical-align: baseline;
			transition: bottom 0.3s ease;
			
			&.formIsOpen{
				bottom: calc(var(--home-height-case-newsletter) + var(--esp-big));
				transition: bottom 0.3s ease;

			}
		}
	}

	&--form{

		width: 100%;

		height: var(--home-height-case-newsletter);
		font-size: var(--home-font-case-newsletter);

		position: absolute;
		bottom: 0vw;

		text-align: center;
		line-height: 1;
	}
}

</style>