<template>

	<router-link 
	v-bind:to=" '/' + $route.params.language + '/' + content.uid "
	class="HomepageArticleVideo" 
	:style="{'background-color': whatColor}">


		<div class="HomepageArticleVideo--img-container">
			<video class="HomepageArticleVideo--img-container--img" autoplay loop muted playsinline :poster="content.cover.video_cover[0].poster">
				<source :src="content.cover.video_cover[0].url" type="video/mp4" />
			</video>
		</div>

		<div class="HomepageArticleVideo--text-container">
			<div class="HomepageArticleVideo--text-container--title" v-html="content.title"></div>
			<div class="HomepageArticleVideo--text-container--text" v-html="content.subtitle_cover"></div>
		</div>	
	</router-link>

</template>

<script>

export default {
  name: 'HomepageArticleVideo',
  props: ['content'],
  computed: {
	whatColor: function(){
		let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
		let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
		return this.content.category === 'art' ? artColor : amateurColor; 
	}
  },
	created(){
		
	}
}
</script>

<style lang="scss">
@import '@/css/variables';

.HomepageArticleVideo{

	width: 100%;

	max-height: calc(1.8 * var(--home-case-height-video));

	display: flex;
	flex-wrap: wrap;

	overflow: hidden;
	z-index: 2;

	&--img-container{

		width: calc(100vw - var(--marge-page) - (2 * var(--esp-med)) );
		object-fit: cover;

		max-height: 80%;

		margin-top: var(--esp-med);
		margin-left: var(--esp-med);
		margin-right: var(--esp-med);

		overflow: hidden;

		&--img{
			width: 100%;
			height: 100%;
			transform: scale(1.03);
			object-fit: cover;
		}
	}

	&--text-container{
		// margin: var(--esp-med);

		&--title{
			font-size: var(--home-font-case2-title);
			line-height: 0.9;
			
			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-top: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);
		}

		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case2-text);

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-bottom: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);


			& p {

				& em {
					font-family: 'vd-reg';
				}
			}
		}
	}
}

@media (orientation: portrait) {

.HomepageArticleVideo{

	&--img-container{
		// width: calc(100vw - var(--marge-page) - (2 * var(--esp-med)) );
		width: calc(100vw - (2 * var(--esp-med)) );
	}
}

}

</style>