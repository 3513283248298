<template>

	<div class="HomepageNews">

		<div class="HomepageNews--container">

			<div class="HomepageNews--container--content" @click="newsletterClicked = !newsletterClicked">
				<div class="HomepageNews--container--content--text">{{whatContentLangTitle}}</div>
				<div class="HomepageNews--container--content--text2" :class="[newsletterClicked ? 'formIsOpen' : '', 'homenews-' + clientWidth]">{{whatContentLang}}</div>
			</div>

			<div class="HomepageNews--container--form" :class="'homenews-' + clientWidth">
				<transition name="fade-article-news" mode="out-in">
				<Newsletter :context="'homepage-large'" v-if="newsletterClicked"></Newsletter>
				</transition>
			</div>
			
		</div>


	</div>

</template>

<script>
import Newsletter from '@/components/Newsletter.vue'

export default {
  name: 'HomepageArticleNews',
  props: ['content', 'index', 'clientWidth'],
	components:{
		Newsletter
  },
	data: function(){
		return {
			newsletterClicked: false
		}
  },
  computed: {
		whatContentLangTitle: function(){
			if (this.$route.params.language == 'fr'){
				return 'Devenez profane'
			}else {
				return 'Join Profane'
			}
		},
		whatContentLang: function(){
			if (this.$route.params.language == 'fr'){
				return 'Inscrivez-vous à la newsletter'
			}else {
				return 'Subscribe to the newsletter'
			}
		},  
		whatColor: function(){
			let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
			let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
			return this.content.category === 'art' ? artColor : amateurColor; 
		},
		whatDirection: function(){
			if(this.index % 2 === 0){
				return 'row-reverse'
			}else{
				return 'row'
			}
    }
  },
  created(){
		// console.log('ARTICLE NEWS CREATED')
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

// .fade-article-news-enter-active, .fade-article-news-leave-active {
//   transition: opacity 0.3s;
// }
// .fade-article-news-leave-active {
//   transition: opacity 0.15s;
// }
// .fade-article-news-enter, .fade-article-news-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }

// .fade-article-news-enter-active, .fade-article-news-leave-active {
//   transition: transform 0.3s;
// }
// .fade-article-news-leave-active {
//   transition: transform 0.15s;
//   transform: translate(0, 0);
// }
// .fade-article-news-enter, .fade-article-news-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   transform: translate(0,100%);
// }

.HomepageNews{
	width: 100%;
	// height: var(--home-case-height);
	height: var(--home-case-height);
	z-index: 2;

	margin-top: var(--esp-med);
	margin-bottom: var(--esp-med);
	// margin-bottom: 90vh;

}


.HomepageNews--container{
	cursor: pointer;
	overflow: hidden;

	width: 100%;
	height: 100%;

	background-color: rgb(230,230,230);
	position: relative;

	&--content{
		// position: relative;
		width: 100%;
		height: 100%;
		padding: var(--esp-med);

		&--text{
			font-size: var(--home-font-case3-title);
			line-height: 0.9;

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;
		}

		&--text2{
			font-size: var(--home-font-case2-title);
			line-height: 0.9;

			position: absolute;

			// bottom: calc(var(--esp-med) - (0.09 * var(--home-font-case2-title)) );

			vertical-align: baseline;
			transition: bottom 0.3s ease;

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			bottom: var(--esp-med);

			&.homenews-mobileWidth{
				font-size: var(--home-font-case1-title);
			}

			&.formIsOpen{
				bottom: calc((1.3 * var(--home-height-case-newsletter)) + var(--esp-med));
				transition: bottom 0.3s ease;
			}

			&.formIsOpen.homenews-mobileWidth{
				bottom: calc(var(--home-height-case-newsletter) + var(--esp-med));
				transition: bottom 0.3s ease;
			}
		}
	}

	&--form{

		width: 100%;

		height: calc(1.3 * var(--home-height-case-newsletter));
		font-size: calc( 1.3 * var(--home-font-case-newsletter));

		position: absolute;
		bottom: 0vw;

		text-align: center;
		line-height: 1;

		&.homenews-mobileWidth{
		height: var(--home-height-case-newsletter);
		font-size: var(--home-font-case-newsletter);
		}
	}
}



@media (orientation: portrait) { 

.HomepageArticleNews{

	width: calc( 100% );
	z-index: 50;

	&--article{
		width: calc( (100% - var(--esp-med)) / 2 );
	}

	&--newsletter{
		width: calc( (100% - var(--esp-med)) / 2 );
	}
}
}







</style>