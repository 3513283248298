import { isMobileOnly, isBrowser, isMobile, isTablet, isIOS, deviceDetect} from 'mobile-device-detect';
// https://github.com/duskload/mobile-device-detect#readme

class Client {

	constructor(){

		this.state = {
			device: '',
			format: '',
			scale: '',
			ratio: '',	
			menuFormat: '',
			isInit: false,
			viewTransition: '',
			isKeepAlive: false
		}

		this.breakPoint = {
			width: 1.34,
			height: 2.2
		}

		this.mobileMaxWidth = 650 // prob avec ipad 
		this.tabletMaxWidth = 1024
		this.desktopWidth = {
			small: 1280,
			medium: 1560,
			big: 1920,
			bigger: 2200,
			max: 2500
		}

		this.isInit = false

		this.listenerActiv = false

		this.windowListener = this.windowListener.bind(this);

		this.windowListener()


		this.oldIndex = 0

		// this.isKeepAlive = false

		// console.log('CLIENT IS INITTTTTTTTTTT')
	}

	/**
	 * Gère le transition entre site normal et shop
	 * 	  - Menu.vue
	 *    - Boutique.vue
	 *    - Product.vue
	 */

	setViewTransition(to, from){
		let newStateViewTransition
		// console.log('setViewTransition', to, from)


		/** V.LANDSCAPE */

		// site normal -> vers boutique
		if ( to == 'boutique' && (from == 'home' || from == 'art' || from == 'amateur' || from == 'recherche' || from == 'article' || from == 'decouvertes')){
			// console.log('ANIMATE from home to boutique')
			// console.log('setViewTransition to right')
			newStateViewTransition = 'toggleShop'
			
		} 
		// boutique -> vers site normal
		if ( to == 'home' && (from == 'boutique' || from == 'product')){
			// console.log('ANIMATE from boutique to home')
			// console.log('setViewTransition to left')
			newStateViewTransition = 'toggleShopReturn'
		}  

		// boutique -> boutique
		if ( to == 'boutique' && from == 'product' || to == 'product' && from == 'boutique'){
			this.state.viewTransition = ''
		}


		/** V.MOBILE & V.PORTRAIT */
		
		if (this.state.format == 'portrait'){
			newStateViewTransition = ''
		}


		this.state.viewTransition = newStateViewTransition

		// console.log(this.state.viewTransition)

		/// site normal -> shop (boutique + product)
		/// shop (boutique + product) -> site normal
		
		/// shop boutique -> shop product
		/// shop product -> shop boutique

		/// init shop
		/// init product

		// version portrait + mobile

	}

	getViewTransition(){
		// console.log(this.state.viewTransition)
		return this.state.viewTransition
	}

	setListener(){
		if (!(this.listenerActiv)){
			window.addEventListener('resize', this.windowListener)
			this.listenerActiv = true
		}
	}

	isListenerActiv(){
		return this.listenerActiv
	}

	removeListener(){
		if (this.listenerActiv){
			window.removeEventListener('resize', this.windowListener)
			this.listenerActiv = false
		}
	}

	windowListener(){
		// console.log('client windows listener')

		isMobileOnly, isBrowser

		if ( isMobile || isTablet){

			if (isMobile){
				this.state.device = 'mobile'
			}
			if (isTablet){
				this.state.device = 'tablet'
			}

		} else { // desktop - if (isBrowser)
			this.state.device = 'desktop'
		}

		// WINDOW_WIDTH

		let windowWidth = window.innerWidth
		let mobileWidth = windowWidth <= this.mobileMaxWidth
		let tabletWidth = windowWidth > this.mobileMaxWidth && windowWidth <= this.tabletMaxWidth
		let deskstopSmall = windowWidth > this.tabletMaxWidth && windowWidth <= this.desktopWidth.small
		let desktopMed = windowWidth > this.desktopWidth.small && windowWidth <= this.desktopWidth.medium
		let desktopBig = windowWidth > this.desktopWidth.medium && windowWidth <= this.desktopWidth.big
		let desktopBigger = windowWidth > this.desktopWidth.big && windowWidth <= this.desktopWidth.bigger
		let desktopBiggest = windowWidth > this.desktopWidth.bigger && windowWidth <= this.desktopWidth.max
		let desktopMax = windowWidth > this.desktopWidth.max

		if (mobileWidth){ this.state.scale = 'mobileWidth' }
		if (tabletWidth){ this.state.scale = 'tabletWidth' }
		if (deskstopSmall){ this.state.scale = 'deskstopSmall' }	
		if (desktopMed){ this.state.scale = 'desktopMed' }	
		if (desktopBig){ this.state.scale = 'desktopBig'  }
		if (desktopBigger){ this.state.scale = 'desktopBigger' }
		if (desktopBiggest){ this.state.scale = 'desktopBiggest' }		
		if (desktopMax){ this.state.scale = 'desktopMax' }


		// FORMAT & RATIO

		this.state.resolution = { height: window.innerHeight, width: window.innerWidth }
		this.state.ratio = this.state.resolution.width / this.state.resolution.height

		if (this.state.ratio <= 1 ){
			this.state.format = 'portrait'
		}

		if (this.state.ratio > 1 ){
			this.state.format = 'landscape'
		}


		// BREAKPOINT SPECIFIQUE

		if (this.state.ratio < this.breakPoint.width){
			this.state.menuFormat = 'carre'
		}

		if (this.state.ratio > this.breakPoint.width && this.state.ratio < this.breakPoint.height){
			this.state.menuFormat = 'normal'
		}

		if (this.state.ratio > this.breakPoint.height){
			this.state.menuFormat = 'rectangle'
		}
	}	

	getConditionnal(obj){
		// console.log('CLIENT GET_COND')
		let result

		if (this.state.device == 'mobile'){  // mobile
			
			if (this.state.format == 'landscape') { // mobile - landscape
				result = obj.tablet
			}else {
				result = obj.mobile 	// mobile - portrait
			}
		}

		if (this.state.device == 'tablet'){  // tablet 
			result = obj.tablet
		}		

		if (this.state.device == 'desktop' && this.state.format == 'landscape'){  // desktop - landscape
			result = obj.desktopLandscape
		}

		if (this.state.device == 'desktop' && this.state.format == 'portrait' && this.state.scale == 'mobileWidth'){  // dekstop - portrait - mobileWidth
			result = obj.desktopPortraitMobile
		}

		if (this.state.device == 'desktop' && this.state.format == 'portrait' && !(this.state.scale == 'mobileWidth')){  // dekstop - portrait - !mobileWidth
			result = obj.desktopPortraitNotMobile
		}

		// ajouter desktopPortraitMax








		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'deskstopSmall'){  // desktop - landscape
			result = obj.desktopLandscapeSmall
		}


		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'desktopMed'){  // desktop - landscape
			result = obj.desktopLandscapeMed
		}


		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'desktopBig'){  // desktop - landscape
			result = obj.desktopLandscapeBig
		}

		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'desktopBigger'){  // desktop - landscape
			result = obj.desktopLandscapeBigger
		}

		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'desktopBiggest'){  // desktop - landscape
			result = obj.desktopLandscapeBiggest
		}

		if (this.state.device == 'desktop' && this.state.format == 'landscape' && this.state.scale == 'desktopMax'){  // desktop - landscape
			result = obj.desktopLandscapeMax
		}


		// if landscape
		// 		if width ==
		// 		if width ==
		// 		if width ==

		// if portrait
		// 		if width ==
		// 		if width ==
		// 		if width ==


		// receipt by whatMenu
		// receipt by watch ClientWidth (setVariables) 
		// receipt by watch ClientFormat (setVariables)

		// console.log(result)
		return result
	}

	getDevice(){
		return this.state.device
	}

	isOldIos(){

		if(isIOS){

			let osVersion = Number(deviceDetect().osVersion.substr(0, 2))



			if ( osVersion < 13 ){
				// console.log('ios version old', deviceDetect())
				return true
			}
			return false
		}
		return false

	}

	getFormat(){
		return this.state.format
	}

	getWidth(){
		return this.state.scale
	}

	getMenuFormat(){
		return this.state.menuFormat
	}

	setOldRedirectShopIndex(oldIndex){
		// console.log('setOldRedirectShopIndex', oldIndex)
		this.oldIndex = oldIndex
	}

	getOldRedirectShopIndex(){
		// console.log('getOldRedirectShopIndex')
		return this.oldIndex
	}

	setKeepAlive(val){
		this.state.isKeepAlive = val
	}

	getKeepAlive(){
		// console.log(this.isKeepAlive)
		return this.state.isKeepAlive
	}
}


export default {
	init: () => {
		return new Client ()
	}
}

























