<template>

  <div class="PopCookie">
    <div class="PopCookie--content">

      <div class="PopCookie--content--text">
        <p v-if="$route.params.language == 'fr'">Cette boutique utilise des cookies pour garantir la meilleure expérience.</p>
        <p v-if="$route.params.language == 'en'">This shop uses cookies tu ensure you get the best experience.</p>
      </div>
        
      <div class="PopCookie--content--button">

        <div class="PopCookie--content--button--decline" @click="setDecline()">
          <p v-if="$route.params.language == 'fr'">Refuser</p>
          <p v-if="$route.params.language == 'en'">Decline</p>
        </div>
        <div class="PopCookie--content--button--confirm" @click="setConfirm()">
          <p v-if="$route.params.language == 'fr'">Accepter</p>
          <p v-if="$route.params.language == 'en'">Confirm</p>
        </div>
        
      </div>

    </div>

  </div>


</template>

<script>
export default {
  name: 'PopCookie',
  props: ['content', 'context'],
  computed:{

  },
  methods: {
    setConfirm: function(){

      // console.log('setConfirm')
      document.cookie = 'cookieEnable=true'

      this.$emit('cookie-validate', true)

    },

    setDecline: function(){

      console.log('setDecline')
      document.cookie = 'cookieEnable=true'

      this.$emit('cookie-validate', true)
    }

  },
  created(){
    // console.log(this.context)
  },
  mounted(){

    setTimeout(() => { this.$el.style.opacity = 1; }, 500);
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.PopCookie{
  position: fixed;
  width: 100%;
  // height: var(--shop-menu-header-height);
  font-size: calc(var(--shop-menu-header-font) / 5 * 2.5);

  background-color: white;

  z-index: 2000;

  bottom: 0;

  transition: opacity 0.5s ease;
  opacity: 0;

  &--content{
    display: flex;
    justify-content: space-between;
    padding: var(--esp-med);

    &--text{

    }

    &--button{
      padding-left: calc(3 * var(--esp-med));
      display: flex;

      &--decline{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }

      &--confirm{

        cursor: pointer;
        padding-left: calc(2 * var(--esp-med));

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }

    }
  }

}


</style>