<template>
  <div class="MenuTablet" @mousemove.prevent="cursorOnMenu" >

      <div v-if="cursorOnMenuValue" class="MenuTablet--stop-mousemove" :class="this.pageIsArticle ? 'article': 'page'"></div>
      <div v-if="cursorSecurity" class="MenuTablet--security" :style="securityPosition"></div>

      <MenuCase 
      v-for="(element, index) in $site.menu"
      :key="index"
      :index="index" :whatzIndex="whatzIndex[index]" :uid="contentMenu[index].uid" :color="contentMenu[index].color" 
      @mouse-enter="mouseEnter"
      @mouse-leave="mouseLeave"
      @testclick="testclick">

        <template v-if="index == 0">
        <div class="MenuTablet--case--left title">Profane</div>
        <div class="MenuTablet--case--right-info">
          <div class="MenuTablet--case--right-info--top">
            <div class="MenuTablet--case--right-info--top--langage">
              fr / en
            </div>
            <div class="MenuTablet--case--right-info--top--newsletter">
              <a :href="$site.links.newsletter">Newsletter</a>
            </div>       
          </div>
          <div class="MenuTablet--case--right-info--bottom">
            <div class="MenuTablet--case--right-info--bottom--links">
              <a :href="$site.links.ig">Instagram</a><br>
              <a :href="$site.links.fb">Facebook</a><br>
              <a :href="$site.links.yt">Youtube</a>
            </div>
          </div>
        </div>
        </template>

        <template v-else>
          <div class="MenuTablet--case--left" :style="{'color': index == 5? 'white': ''}">Profane</div>
          <div class="MenuTablet--case--right" :style="{'color': index == 5? 'white': ''}">{{contentMenu[index].name}}</div>
        </template>

      </MenuCase>

  </div>
</template>

<script>
import MenuCase from '@/components/menu/MenuCase.vue'

import debounce from 'lodash/debounce';
import CSSvariables from '@/css/variables.scss'

export default {
  name: 'MenuTablet',
  props: ['ClientFormat', 'ClientDevice', 'contentMenu'],
  components: {
    MenuCase
  },
  
  data: function(){
    return {
      tabMenuIsHover: [false,false,false,false,false,false],
      cursorPositionX: '',
      cursorPositionY: '',
      MenuPosition: {
        start : '',
        end: ''
      },
      cursorOnMenuValue: '',
      cursorSecurity: false,
      isClicked: '',
      pageIsArticle: ''
    }
  },

  computed: {
    whatzIndex: function(){
        // console.log('whatzIndex')
        if (this.cursorOnMenuValue){
          return this.tabMenuIsHover.map(function(item) {
              return item;
          });
        }else {
          return 1
        }
    },

    securityPosition: function(){
      return {top: (100 / 6) * this.isClicked + 'vh'}
    },
  },

  watch: {
    cursorOnMenuValue: function(val){
      // console.log(val)
      if (val == false){
        this.cursorSecurity = false
      }
    },
    '$route': {
      immediate: true,
      handler(val) {
      // this.pageIsArticle = val.name == 'article' ? true : false
      val
      this.pageIsArticle = true
      this.setMenuPosition()
      }
    }
  },
  methods: {
    testclick: function(index){
      this.isClicked = index
      this.cursorSecurity = true
      this.tabMenuIsHover.forEach((element, index) => {
        this.$set(this.tabMenuIsHover, index, false)
      })
    },
    mouseEnter: function(index){
      this.$set(this.tabMenuIsHover, index, true)
    },
    mouseLeave: function(index){
      this.$set(this.tabMenuIsHover, index, false)
    },
    cursorOnMenu: function(e){ 
      this.cursorPositionX = e.clientX
      this.cursorPositionY = e.clientY
      
      let conditionPage = this.cursorPositionX < this.MenuPosition.start || this.cursorPositionX > this.MenuPosition.end
      let conditionArticle = this.cursorPositionX < this.MenuPosition.start || this.cursorPositionY < this.MenuPosition.top
      let condition = this.pageIsArticle ? conditionArticle : conditionPage

      let result = condition ? true : false
      if ( this.cursorOnMenuValue != result ){
        this.cursorOnMenuValue = condition ? true : false 
      }
    },
    setMenuPosition: function(){ // resize
      let windowWith = window.innerWidth
      // console.log(this.ClientDevice)

      if (this.pageIsArticle){
        let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article');
        let margeArticle = Number(margeArticleRaw.split('vw')[0])

        this.MenuPosition.start = windowWith * (margeArticle) / 100
        this.MenuPosition.top = windowWith * (margeArticle) / 100

      }else {
        let pagewidth = Number(CSSvariables.pagewidth.split('vw')[0])
        
        let margePage = (100 - pagewidth) / 2
        this.MenuPosition.start = windowWith * (margePage) / 100
        this.MenuPosition.end = windowWith * ( 100 - (margePage)) / 100
      }
    },
    onResize: function (){
      this.setMenuPosition()
    }
  },
  mounted(){
    this.setMenuPosition()
    this.onResizeDebounced = debounce(this.onResize, 100)
    window.addEventListener('resize', this.onResizeDebounced)
    // window.addEventListener('resize', this.onResize)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResizeDebounced)

    // window.removeEventListener('resize', this.onResize)

  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.MenuTablet{

  position: absolute;

  width: 100vw;
  height: 100vh;

  overflow: hidden;

  &--stop-mousemove{

    position: fixed;


    z-index: 50;

    background-color: rgba( 210, 100, 100, 0);

    &.page{

      height: 100vh;
      width: $page-width;
      left: calc(#{$marge-page} / 2); 

    }

    &.article{

      left: $margeArticleWidth;
      top: $margeArticleWidth;

      width: calc(100vw - #{$margeArticleWidth});
      height: calc(100vh - #{$margeArticleWidth});

    }

  }

  &--security{

    position: fixed;
    // height: 100vh;
    width: 100vw;
    z-index: 50;

    height: calc(100vh / 6);

    background-color: rgba( 210, 0, 0, 0);

  }


    &--case{

    display: block;

    width: 100vw;
    height: calc(16.7vh); 
    align-items: center;

    // display: flex;
    // flex-wrap: nowrap;
    // justify-content: space-between;
    

    position: fixed;


    &--left{      
      
      line-height: 0.7;
      margin-left: 0.5vw;

      position: absolute;
      float: left;

      height: 100%;
      align-items: center;
      top: 15%;

      opacity: 0.1;

      // font-size: 20vh; 
      font-size: clamp( 18vh, 20vh, 32vw);

      &.title{
        opacity: 1;
      }
    }

    &--right{
      // align-self: flex-end;
      
      line-height: 1;
      margin-right: 1vw;
      right: 1vw;

      position: absolute;
      float: right;

      height: 100%;
      align-items: center;
      top: 15%;


      font-size: 14vh; 
      font-size: clamp( 1px, 14vh, 18.8vw);

    }

    
  }







.MenuTablet--case--right-info{
  font-size: 2vh;


  text-align: right;

  position: relative;
  height: 100%;

  &--top{
    position: absolute;
    top: 0.7vh;
    right: 0.7vh;

    &--langage{}
    &--newsletter{}

  }

  &--bottom{
    position: absolute;
    bottom: 0.7vh;
    right: 0.7vh;

  &--links{}

  }
}

}




</style>