class Datastore {

	constructor () {

		this.state = {
			fr: {
				site: {}, 
				home: {},
				art: {},
				amateur: {},
				recherche: {},
				decouvertes: {},
				article: {},

				boutique: {},
				product: {},

				redirectShop: {}
			},
			en: {
				site: {}, 
				home: {},
				art: {},
				amateur: {},
				recherche: {},
				decouvertes: {},
				article: {},

				boutique: {},
				product: {},

				redirectShop: {}
			}
		}
	}

	pageIsLoaded(langage, page){

		if (Object.keys(this.state.[langage].[page]).length === 0){
			return false
		}else {
			return true
		}	
	}

	getPage(langage, page){	
		return this.state.[langage].[page];
	}

	setPage(langage, page, content){
		this.state.[langage].[page] = content;
	}

	setRedirectShop(langage, content){
		if ( Object.keys(this.state.[langage].redirectShop).length === 0 ){
			// console.log('DATASTORE REDIRECTSHOP ADD CONTENT')
			this.state.[langage].redirectShop = content;
		}else{
			// console.log('DATASTORE REDIRECTSHOP CONTENT ALREADY LOADED')
		}
	}

	getRedirectShop(langage){
		return this.state.[langage].redirectShop
	}

	setArticle(langage, ArticleId , content){
		ArticleId
		this.state.[langage].article = content	
		// fonctionne car methode deja declaré à l'init mais 1 seul article possible
	}

	getArticle(langage, article){
		langage
		article
	}

	resetArticle(langage){
		this.state.[langage].article = {}
	}

	resetProduct(langage){
		this.state.[langage].product = {}
	}

	getMetaContent(envMetaContent){
		return 'Profane - ' + envMetaContent.title
	}

	setDynamicMetaContent(langage, page, envMetaContent){

		// 0. langage

		// document.documentElement.setAttribute('lang', langage)

		// 1. balise title

		if (page == 'article' || page == 'product'){
			let newTitle = 'Profane - ' + this.state.[langage].[page].title_raw.replace('&shy;', '')
			document.title = newTitle
			// this.setOgContent(langage, page, newTitle)
		}else if (page == 'boutique'){
			let newTitle = 'Profane - ' + envMetaContent.titleShop
			document.title = newTitle
			// this.resetOgContent(newTitle)
		}else {
			let newTitle = 'Profane - ' + envMetaContent.title
			document.title = newTitle
			// this.resetOgContent(newTitle)
		}

	}

	setOgContent(langage, page, newTitle){ // SSR needed
		// title
		document.querySelector('meta[property="og:title"]').setAttribute("content", newTitle);
		document.querySelector('meta[property="twitter:title"]').setAttribute("content", newTitle);

		// image
		let newUrl = this.state.[langage].[page].metacover
		console.log(newUrl)
		document.querySelector('meta[property="og:image"]').setAttribute("content", newUrl);
		document.querySelector('meta[property="twitter:image"]').setAttribute("content", newUrl);

		// url
		document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);
		document.querySelector('meta[property="twitter:url"]').setAttribute("content", window.location.href);
	}

	resetOgContent(newTitle){ // SSR needed
		document.querySelector('meta[property="og:title"]').setAttribute("content", newTitle);
		document.querySelector('meta[property="og:image"]').setAttribute("content", '');
		document.querySelector('meta[property="og:url"]').setAttribute("content", '');

		document.querySelector('meta[property="twitter:title"]').setAttribute("content", newTitle);
		document.querySelector('meta[property="twitter:image"]').setAttribute("content", '');
		document.querySelector('meta[property="twitter:url"]').setAttribute("content", '');
	}

	setStaticMetaContent(lang, envMetaContent){

		// 0. lang
		document.documentElement.setAttribute('lang', lang)

		// 1. balise description
		document.querySelector('meta[name="description"]').setAttribute("content", envMetaContent.description);
		document.querySelector('meta[property="og:description"]').setAttribute("content", envMetaContent.description);
		document.querySelector('meta[property="twitter:description"]').setAttribute("content", envMetaContent.description);


		// 2. balise keywords
		document.querySelector('meta[name="keywords"]').setAttribute("content", envMetaContent.keywords);
	}
}


export default {
	init: () => {

		return new Datastore()

	}
}


