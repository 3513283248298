<template>

	<div class="HomepageArticleNews">

		<router-link 
		v-bind:to=" '/' + $route.params.language + '/' + content.uid "
		class="HomepageArticleNews--article" 
		:style="{'background-color': whatColor}"
		:class="whatDirection"
		>
			<!-- <img class="HomepageArticleNews--article--img-container" :src="content.cover.url" :alt="content.title"> -->
			<!-- <img class="HomepageArticleNews--article--img-container" :alt="content.title" :src="content.cover.url" :srcset="content.cover.srcset" sizes="25vw"> -->
			<img class="HomepageArticleNews--article--img-container"
			v-lazyloading 
			:alt="content.subtitle_cover" 
			:src="content.covers.landscape.thumb" 
			:data-srcset="content.covers.landscape.srcset"
			sizes="25vw">

			<div class="HomepageArticleNews--article--text-container">
				<div class="HomepageArticleNews--article--text-container--title" v-html="content.title"></div>
				<div class="HomepageArticleNews--article--text-container--text" v-html="content.subtitle_cover"></div>
			</div>	
		</router-link>

	<div class="HomepageArticleNews--newsletter">

		<div class="HomepageArticleNews--newsletter--content" @click="newsletterClicked = !newsletterClicked">
			<div class="HomepageArticleNews--newsletter--content--text">{{whatContentLangTitle}}</div>
			<div class="HomepageArticleNews--newsletter--content--text2" :class="newsletterClicked ? 'formIsOpen' : ''">{{whatContentLang}}</div>

		</div>

		<div class="HomepageArticleNews--newsletter--form">
			<transition name="fade-article-news" mode="out-in">
			<Newsletter :context="'homepage'" v-if="newsletterClicked"></Newsletter>
			</transition>
		</div>
		
	</div>


	</div>

</template>

<script>
import Newsletter from '@/components/Newsletter.vue'

export default {
  name: 'HomepageArticleNews',
  props: ['content', 'index'],
	components:{
		Newsletter
  },
	data: function(){
		return {
			newsletterClicked: false
		}
  },
  computed: {
		whatContentLangTitle: function(){
			if (this.$route.params.language == 'fr'){
				return 'Devenez profane'
			}else {
				return 'Join Profane'
			}
		},
		whatContentLang: function(){
			if (this.$route.params.language == 'fr'){
				return 'Inscrivez-vous à la newsletter'
			}else {
				return 'Subscribe to the newsletter'
			}
		},  
		whatColor: function(){
			let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
			let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
			return this.content.category === 'art' ? artColor : amateurColor; 
		},
		whatDirection: function(){
			if(this.index % 2 === 0){
				return 'row-reverse'
			}else{
				return 'row'
			}
    }
  },
  created(){
		// console.log('ARTICLE NEWS CREATED')
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

// .fade-article-news-enter-active, .fade-article-news-leave-active {
//   transition: opacity 0.3s;
// }
// .fade-article-news-leave-active {
//   transition: opacity 0.15s;
// }
// .fade-article-news-enter, .fade-article-news-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }

.HomepageArticleNews--article{
	background-color: var(--first-color);
	width: calc( (100vw - var(--marge-page) - var(--esp-med)) / 2 );

	height: var(--home-case-height);
	z-index: 2;



	&--img-container{
		width: 100%;
		// width: auto;
		max-height: 60%;
		object-fit: cover;

	}

	&--text-container{
		align-self: flex-start;

		// margin: var(--esp-med);

		&--title{
			font-size: var(--home-font-case2-title);
			line-height: 0.9;
			word-break: break-word;

			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;


			padding-top: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);
		}

		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case2-text);

			line-clamp: 3;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-bottom: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);

			& p {

				& em {
					font-family: 'vd-reg';
				}
			}
		}
	}
}




.HomepageArticleNews--article{

	&.row{
		display: inline-block;
	}

	&.row-reverse{
	display: flex;
	flex-wrap: wrap-reverse;
	}
	&--text-container{
		// align-self: flex-end;
		align-self: stretch;
	}
}



.HomepageArticleNews{
	
	width: 100%;
	width: calc( (100vw - var(--marge-page)) );

	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.HomepageArticleNews--newsletter{

	cursor: pointer;

	overflow: hidden;

	width: calc( (100vw - var(--marge-page) - var(--esp-med)) / 2 );
	height: var(--home-case-height);

	background-color: rgb(230,230,230);

	position: relative;
	margin-top: var(--esp-med);

	&--content{
		width: calc(100% - 2 * var(--esp-med));
		height: calc(var(--home-case-height) - 2 * var(--home-height-case-newsletter) - 2 * var(--esp-med));

		margin: var(--esp-med);


		&--text{
			font-size: var(--home-font-case1-title);
			line-height: 0.9;

			// line-clamp: 2;
			// display: -webkit-box;
			// -webkit-line-clamp: 2;
			// -webkit-box-orient: vertical;  
			// overflow: hidden;
		}

		&--text2{
			font-size: var(--home-font-case2-title);
			line-height: 0.9;

			position: absolute;

			bottom: calc(var(--esp-med) - (0.09 * var(--home-font-case2-title)) );

			vertical-align: baseline;
			transition: bottom 0.3s ease;

			// line-clamp: 2;
			// display: -webkit-box;
			// -webkit-line-clamp: 2;
			// -webkit-box-orient: vertical;  
			// overflow: hidden;


			
			&.formIsOpen{
				bottom: calc(var(--home-height-case-newsletter) + var(--esp-med) - (0.09 * var(--home-font-case2-title)));
				transition: bottom 0.3s ease;

			}
		}
	}

	&--form{

		width: 100%;

		height: var(--home-height-case-newsletter);
		font-size: var(--home-font-case-newsletter);

		position: absolute;
		bottom: 0vw;

		text-align: center;
		line-height: 1;
	}
}



@media (orientation: portrait) { 

.HomepageArticleNews{

	width: calc( 100% );

	z-index: 50;

	&--article{
		width: calc( (100% - var(--esp-med)) / 2 );
	}

	&--newsletter{
		width: calc( (100% - var(--esp-med)) / 2 );

	}
}

}







</style>