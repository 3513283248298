import CSSvariables from '@/css/variables.scss'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

function setResizeObserver(cible, callback){

  const ResizeObserver = window.ResizeObserver || Polyfill;

  let observeHeight = () => {
    const resizeObserver = new ResizeObserver(() => callback() );
    resizeObserver.observe(cible);
  }  
  observeHeight();
}

function setResizeObserverWithReturn(cible, callback){

  const ResizeObserver = window.ResizeObserver || Polyfill;

  let observer
  let observeHeight = () => {
    observer = new ResizeObserver(() => callback() );
    observer.observe(cible);
  }  
  observeHeight();

  return observer
}

function toggleResizeObserver(observer, cible, toggle){

	if (toggle){
		// console.log('toggleResizeObserver observe')
		observer.observe(cible)
	}else{
		// console.log('toggleResizeObserver unobserve')
		observer.unobserve(cible)
	}
}


/*__SHOP__________________________*/

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

const shopToggleHide = function(){

  let toggleCart = document.querySelector('#snipcart-toggle')
  // console.log(toggleCart)
  if(toggleCart){
    document.querySelector('#snipcart-toggle').classList.add('isHiding')
    document.querySelector('#snipcart-toggle').classList.remove('is-active')
  }

}

const shopToggleAppear = function(){

  let toggleCart = document.querySelector('#snipcart-toggle')
  if(toggleCart){
    document.querySelector('#snipcart-toggle').classList.remove('isHiding')
    document.querySelector('#snipcart-toggle').classList.add('is-active')
  }
}

const shopCartHide = function(){

  let cart = document.querySelector('#snipcart-cart')
  if (cart){
    cart.classList.remove('is-activeManual')
  }

}

const shopCartAppear = function(){

  let cart = document.querySelector('#snipcart-cart')
  if (cart){
    cart.classList.remove('is-activeManual')
  }
	
}

/*__CONTENT-CONFIG__________________________*/


/**
 * Article.vue/CurentTitle.vue
 */

const getArticleCategory = function(category, lang){

	let formatedCategory
	let uid 

	if (category == 'art' && lang == 'fr'){
		formatedCategory = 'Art amateur'
		uid = '/art-amateur'
	}

	if (category == 'amateur' && lang == 'fr'){
		formatedCategory = "Amateur d'art"
		uid = '/amateur-d-art'
	}

	if (category == 'art' && lang == 'en'){
		formatedCategory = 'Amateur Art'
		uid = '/art-amateur'
	}

	if (category == 'amateur' && lang == 'en'){
		formatedCategory = "Art amateur"
		uid = '/amateur-d-art'
	}

	return {formatedCategory, uid}
}

/**
 * App.vue/Menu.vue
 */
const getContentMenu = function(lang, tabMenu){

	// console.log(tabMenu)

	return [
		{
			name: 'homepage',
			uid: '',
			color: 'white'
		},
		{
			name: tabMenu.title[1],
			uid: '/' + tabMenu.uid[1],
			color: 'var(--first-color)',
			colorLighten: 'var(--first-color-lighter)'
		},
		{
			name: tabMenu.title[2],
			uid: '/' + tabMenu.uid[2],
			color: 'var(--second-color)',
			colorLighten: 'var(--second-color-lighter)'
		},
		{
			name: tabMenu.title[3],
			uid: '/' + tabMenu.uid[3], 
			color: 'var(--third-color)',
			colorLighten: 'var(--third-color-lighter)',
		},
		{
			name: tabMenu.title[4],
			uid: '/' + tabMenu.uid[4], 
			color: 'var(--fourth-color)',
			colorLighten: 'var(--fourth-color-lighter)'
		},
		{
			name: tabMenu.title[5],
			uid: '/' + tabMenu.uid[5], 
			color: 'black',
			colorLighten: 'rgba(255,255,255,0.2)'
		}
	]


	// return [
	// 	{
	// 		name: 'homepage',
	// 		uid: '',
	// 		color: 'white'
	// 	},
	// 	{
	// 		name: lang === 'fr' ? "Art amateur" : "Art amateur (EN)",
	// 		uid: '/art-amateur',
	// 		color: 'var(--first-color)',
	// 		colorLighten: 'var(--first-color-lighter)'
	// 	},
	// 	{
	// 		name: lang === 'fr' ? 'Amateur d\'art' : 'Amateur d\'art (EN)' ,
	// 		uid: '/amateur',
	// 		color: 'var(--second-color)',
	// 		colorLighten: 'var(--second-color-lighter)'
	// 	},
	// 	{
	// 		name: lang === 'fr' ? 'Découvertes' : 'Découverte (EN)',
	// 		uid: '/univers', 
	// 		color: 'var(--third-color)',
	// 		colorLighten: 'var(--third-color-lighter)',
	// 	},
	// 	{
	// 		name: lang === 'fr' ? 'Recherche' : 'Recherche (EN)',
	// 		uid: '/recherche', 
	// 		color: 'var(--fourth-color)',
	// 		colorLighten: 'var(--fourth-color-lighter)'
	// 	},
	// 	{
	// 		name: lang === 'fr' ? 'Boutique' : ' Boutique (EN)',
	// 		uid: '/boutique', 
	// 		color: 'black',
	// 		colorLighten: 'rgba(255,255,255,0.2)'
	// 	}
	// ]
}

/*__COLORS__________________________*/

const setColors = function(tabColors){
	
	let index = getRandom(tabColors.length)
	index
	let colors = tabColors[index]

	let variablesObj = {
		'--first-color': colors[0],
		'--second-color': colors[1],
		'--third-color': colors[2],
		'--fourth-color': colors[3],
		// '--shop-color': colors[3],
		'--shop-color': '#9963a5',
		'--first-color-lighten': getLightenColor(colors[0], 20),
		'--second-color-lighten': getLightenColor(colors[1], 20),
		'--third-color-lighten': getLightenColor(colors[2], 20),
		'--fourth-color-lighten': getLightenColor(colors[3], 30),
		'--shop-color-lighten': getLightenColor('#9963a5', 20),
		'--first-color-lighter': getLightenColor(colors[0], 10),
		'--second-color-lighter': getLightenColor(colors[1], 10),
		'--third-color-lighter': getLightenColor(colors[2], 10),
		'--fourth-color-lighter': getLightenColor(colors[3], 10),
		'--shop-color-lighter': getLightenColor('#9963a5', 10),

		'--shop-bg-color': 'rgba(238,238,238,1)',
	}
	setVars(variablesObj)
}

const setColorsArticle = function(color, lightenColor){
	let variablesObj = { '--article-color': color, '--article-color-lighten': lightenColor}
	setVars(variablesObj)
}

const getLightenColor = function(color, percent){
	var num = parseInt(color.replace("#",""),16),
	amt = Math.round(2.55 * percent),
	R = (num >> 16) + amt,
	B = (num >> 8 & 0x00FF) + amt,
	G = (num & 0x0000FF) + amt;
	return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
}


/*__VARIABLES__________________________*/


const getVar = function (){

	let pageWidth = CSSvariables.pagewidth
	let pageWidthTablet = CSSvariables.pagewidthtablet
	let pageWidthMobile = CSSvariables.pagewidthmobile

	let margePage = CSSvariables.margepage
	let margePageTablet = CSSvariables.margepagetablet
	let margePageMobile = CSSvariables.margepagemobile

	let margeArticle = CSSvariables.margearticle
	let margeArticleTablet = CSSvariables.margepagetablet
	let margeArticleMobile = CSSvariables.margepagemobile

	let espSmall = CSSvariables.espsmall
	let espMed = CSSvariables.espMed
	let espBig = CSSvariables.espBig

	let articleViewerWidth = CSSvariables.articleviewerwidth
	let articleThumbsWidth = CSSvariables.articlethumbswidth

	let redirectTitleHeight = CSSvariables.redirecttitleheight
	let redirectTitleHeightTablet = CSSvariables.redirecttitleheightTablet
	let redirectTitleHeightMobile = CSSvariables.redirecttitleheightMobile

	let menuHeaderHeight = CSSvariables.menuheaderheight

	let shopmenuheaderheight = CSSvariables.shopmenuheaderheight
	let shopmenuredirectheight = CSSvariables.shopmenuredirectheight

	return {
		pageWidth,
		pageWidthTablet,
		pageWidthMobile,
		margePage,
		margePageTablet,
		margePageMobile,
		margeArticle,
		margeArticleTablet,
		margeArticleMobile,
		espSmall,
		espMed,
		espBig,
		articleViewerWidth,
		articleThumbsWidth,
		redirectTitleHeight,
		redirectTitleHeightTablet,
		redirectTitleHeightMobile,
		menuHeaderHeight,
		shopmenuheaderheight,
		shopmenuredirectheight
	}
}

const setVars = function(variablesObj){
	for (const property in variablesObj) {
		document.documentElement.style.setProperty(property, variablesObj[property])
	}
}

const setMobileVariables = function (){
	// console.log('setMobileVariables')
	let variablesObj = {
		'--page-width': getVar().pageWidthMobile,
		'--marge-page': getVar().margeArticleMobile,
		'--marge-article': '60px',

		'--esp-small': getVar().espBig,
		'--esp-med': getVar().espBig,
		// '--esp-big': getVar().espBig,
		'--esp-big': '4vw',

		'--card-articles-font-title': '8vw',
		'--card-articles-font-text': '4vw',

		'--header-title-font': '18.5vw',
		'--header-filter-font': '6vw',

		'--cartouche-font-title': '20vw',
		'--cartouche-font-text': '7vw',
		'--cartouche-font-paratexte': '4vw',
		

		'--article-font-text': '5.4vw',
		'--article-font-paratexte': '4vw',
		'--article-font-notes': '4vw',
		'--article-font-legende': '4vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		'--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-maintexte-marge': '0vw',
		'--article-maintexte-width': '100%',

		'--redirect-height': '100vh',
		'--redirect-font-title': '9vw',
		'--redirect-font-text': '5vw',
		'--redirect-title-height': getVar().redirectTitleHeightMobile,
		'--redirect2-font-title': '9vw',

		'--home-case-height' : '85vw',
		'--home-case-height-video' : '65vw',
		'--home-font-case1-title': '14vw',
		'--home-font-case1-text': '5vw',

		'--home-font-case3-title': '20vw',
		'--home-font-case3-text': '5.5vw',
		'--home-height-case-newsletter': '10vw',
		'--home-font-case-newsletter': '8vw',

		'--menu-header-height': '50px',
		'--menu-header-font': '40px',

		'--search-font-input': '12vw',
		'--search-font-keyword': '10vw',

		'--shop-font-labeur': '5vw',
		'--shop-font-labeur-subtitle' : '4vw',
		'--shop-font-labeur-title': '7vw',
		'--shop-font-bold': '7vw',
		'--shop-font-small': '5vw',
		'--shop-font-card-heavy': '9vw',
		'--shop-font-redirect': '2.8vw',

		'--shop-menu-header-height' : '50px',
		'--shop-menu-header-font' : '40px',
		'--shop-menu-redirect-height': '80px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '78px',
		'--shop-header-toggle-right': '0px',
		
		'--univers-font-labeur-title': '14vw',
		'--univers-font-labeur-rgl': '8vw',
		'--univers-font-labeur-small': '2.8vw',
		'--univers-font-title': '19vw',
		'--univers-font-date': '5vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '4vw',
		'--univers-store-font-bold': '5.5vw',

		'--univers-thumb-workshop-width' : '60vw',
		'--univers-thumb-event-width': '30vw'

	}
	setVars(variablesObj)
}

const setTabletVariables = function (){
	// console.log('setTabletVariables')
	let variablesObj = {

		'--page-width': getVar().pageWidthTablet,
		'--marge-page': getVar().margeArticleTablet,
		'--marge-article': getVar().margeArticleTablet,

		'--esp-small': getVar().espMed,
		'--esp-med': getVar().espBig,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '4vw',
		'--card-articles-font-text': '2vw',

		'--header-title-font': '16vw',
		'--header-filter-font': '3vw',


		'--cartouche-font-title': '10vw',
		'--cartouche-font-text': '3.6vw',
		'--cartouche-font-paratexte': '2vw',

		'--article-font-text': '2.6vw',
		'--article-font-paratexte': '1.8vw',
		'--article-font-notes': '1.8vw',
		'--article-font-legende': '1.8vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		'--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-maintexte-marge': '0vw',
		'--article-maintexte-width': '75%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '55vw', // landscape
		'--redirect-font-title': '4vw',
		'--redirect-font-text': '2vw',
		'--redirect-title-height': '5vw',
		'--redirect2-font-title': '6vw',


		'--home-case-height' : '65vw',
		'--home-case-height-video' : '65vw',
		'--home-font-case1-title': '9vw',
		'--home-font-case1-text': '2.8vw',
		'--home-font-case2-title': '6vw',
		'--home-font-case2-text': '2.8vw',
		'--home-font-case3-title': '16vw',
		'--home-font-case3-text': '5vw',
		'--home-height-case-newsletter': '4vw',
		'--home-font-case-newsletter': '3vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '9vw',
		'--search-font-keyword': '8vw',

		'--shop-font-labeur': '2vw',
		'--shop-font-labeur-subtitle' : '2.2vw',
		'--shop-font-labeur-title': '3.2vw',
		'--shop-font-bold': '3.2vw',
		'--shop-font-small': '1.6vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '18px',



		'--shop-menu-header-height' : '60px',
		'--shop-menu-header-font' : '48px',
		'--shop-menu-redirect-height': '80px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '34px',
		'--shop-header-toggle-right': '52px',
		


		'--univers-font-labeur-title': '12vw',
		'--univers-font-labeur-rgl': '4.2vw',
		'--univers-font-labeur-small': '3.2vw',
		'--univers-font-title': '16vw',
		'--univers-font-date': '3vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '2vw',
		'--univers-store-font-bold': '2.6vw',

		'--univers-thumb-workshop-width' : '25vw',
		'--univers-thumb-event-width': '30vw'


	}
	setVars(variablesObj)
 }

const setVariables = function (){
	// console.log('setVariables')
	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,
		'--card-articles-font-title': '3vw',
		'--card-articles-font-text': '1.5vw',

		'--header-title-font': '10vw',
		'--header-filter-font': '2vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2.6vw',
		'--cartouche-font-paratexte': '1.4vw',

		'--article-font-text': '2vw',
		'--article-font-paratexte': '1.4vw',
		'--article-font-notes': '1.4vw',
		'--article-font-legende': '1.4vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		'--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '75%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '55vw', // landscape
		'--redirect-font-title': '4vw',
		'--redirect-font-text': '2vw',
		'--redirect-title-height': '5vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '45vw',
		'--home-case-height-video' : '65vw',
		'--home-font-case1-title': '9vw',
		'--home-font-case1-text': '2vw',
		'--home-font-case2-title': '6vw',
		'--home-font-case2-text': '2vw',
		'--home-font-case3-title': '16vw',
		'--home-font-case3-text': '5vw',
		'--home-height-case-newsletter': '4vw',
		'--home-font-case-newsletter': '3vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.4vw',
		'--shop-font-labeur-subtitle' : '1.6vw',
		'--shop-font-labeur-title': '2.8vw',
		'--shop-font-bold': '3.2vw',
		'--shop-font-small': '1.2vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '18px',

		'--shop-menu-header-height' : '60px',
		'--shop-menu-header-font' : '48px',
		'--shop-menu-redirect-height': '80px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '34px',
		'--shop-header-toggle-right': '52px',
		

		'--univers-font-labeur-title': '9vw',
		'--univers-font-labeur-rgl': '3.2vw',
		'--univers-font-labeur-small': '2.8vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '2vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1.3vw',
		'--univers-store-font-bold': '1.6vw',

		'--univers-thumb-workshop-width' : '25vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)
}


const setDesktopLandscapeSmall = function (){

	// console.log('setDesktopLandscapeSmall')
	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,
		'--card-articles-font-title': '3vw',
		'--card-articles-font-text': '1.5vw',

		'--header-title-font': '10vw',
		'--header-filter-font': '2vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2.6vw',
		'--cartouche-font-paratexte': '1.4vw',

		'--article-font-text': '1.9vw',
		'--article-font-paratexte': '1.4vw',
		'--article-font-notes': '1.4vw',
		'--article-font-legende': '1.4vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		'--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '70%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '55vw', // landscape
		'--redirect-font-title': '3.7vw',
		'--redirect-font-text': '2vw',
		'--redirect-title-height': '4.5vw',
		'--redirect2-font-title': '6vw',


		'--home-case-height' : '45vw',
		'--home-case-height-video' : '65vw',
		'--home-font-case1-title': '9vw',
		'--home-font-case1-text': '2vw',
		'--home-font-case2-title': '6vw',
		'--home-font-case2-text': '2vw',
		'--home-font-case3-title': '16vw',
		'--home-font-case3-text': '5vw',

		'--home-height-case-newsletter': '4vw',
		'--home-font-case-newsletter': '3vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.4vw',
		'--shop-font-labeur-subtitle' : '1.6vw',
		'--shop-font-labeur-title': '2.8vw',
		'--shop-font-bold': '3.2vw',
		'--shop-font-small': '1.2vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '18px',

		'--shop-menu-header-height' : '60px',
		'--shop-menu-header-font' : '48px',
		'--shop-menu-redirect-height': '80px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '34px',
		'--shop-header-toggle-right': '52px',
		

		'--univers-font-labeur-title': '9vw',
		'--univers-font-labeur-rgl': '3.2vw',
		'--univers-font-labeur-small': '2.8vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '2vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1.3vw',
		'--univers-store-font-bold': '1.6vw',

		'--univers-thumb-workshop-width' : '25vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)
}

const setDesktopLandscapeMed = function (){

	// console.log('setDesktopLandscapeMed')
	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '3vw',
		'--card-articles-font-text': '1.5vw',

		'--header-title-font': '10vw',
		'--header-filter-font': '2vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2.6vw',
		'--cartouche-font-paratexte': '1.4vw',

		'--article-font-text': '1.7vw',
		'--article-font-paratexte': '1.3vw',
		'--article-font-notes': '1.3vw',
		'--article-font-legende': '1.3vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		// '--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-thumbs-width': '12vw',
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '66%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '55vw', // landscape
		'--redirect-font-title': '3.7vw',
		'--redirect-font-text': '1.8vw',
		'--redirect-title-height': '4.5vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '45vw',
		'--home-case-height-video' : '65vw',
		'--home-font-case1-title': '9vw',
		'--home-font-case1-text': '1.9vw',
		'--home-font-case2-title': '6vw',
		'--home-font-case2-text': '1.9vw',
		'--home-font-case3-title': '14vw',
		'--home-font-case3-text': '4vw',
		'--home-height-case-newsletter': '3.8vw',
		'--home-font-case-newsletter': '2.9vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.4vw',
		'--shop-font-labeur-subtitle' : '1.4vw',
		'--shop-font-labeur-title': '2.8vw',
		'--shop-font-bold': '3.2vw',
		'--shop-font-small': '1.2vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '18px',

		'--shop-menu-header-height' : '60px',
		'--shop-menu-header-font' : '48px',
		'--shop-menu-redirect-height': '80px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '34px',
		'--shop-header-toggle-right': '52px',
		

		'--univers-font-labeur-title': '9vw',
		'--univers-font-labeur-rgl': '3.2vw',
		'--univers-font-labeur-small': '2.8vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '2vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1.3vw',
		'--univers-store-font-bold': '1.6vw',

		'--univers-thumb-workshop-width' : '25vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)
}

const setDesktopLandscapeBig = function (){

	// console.log('setDesktopLandscapeBig')
	// console.log(window.innerWidth)

	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '2.5vw',
		'--card-articles-font-text': '1.25vw',

		'--header-title-font': '10vw',
		'--header-filter-font': '1.7vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2.6vw',
		'--cartouche-font-paratexte': '1.4vw',

		'--article-font-text': '1.6vw',
		'--article-font-paratexte': '1vw',
		'--article-font-notes': '1vw',
		'--article-font-legende': '1vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		// '--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-thumbs-width': '11vw',
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '63%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '50vw', // landscape
		'--redirect-font-title': '4vw',
		'--redirect-font-text': '1.6vw',
		'--redirect-title-height': '5vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '43vw',
		'--home-case-height-video' : '60vw',
		'--home-font-case1-title': '9vw',
		'--home-font-case1-text': '1.7vw',
		'--home-font-case2-title': '6vw',
		'--home-font-case2-text': '1.7vw',
		'--home-font-case3-title': '14vw',
		'--home-font-case3-text': '4vw',
		'--home-height-case-newsletter': '3.6vw',
		'--home-font-case-newsletter': '2.8vw',


		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.2vw',
		'--shop-font-labeur-subtitle' : '1.3vw',
		'--shop-font-labeur-title': '2.8vw',
		'--shop-font-bold': '3.2vw',
		'--shop-font-small': '1.2vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '20px',

		'--shop-menu-header-height' : '80px',
		'--shop-menu-header-font' : '62px',
		'--shop-menu-redirect-height': '100px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '44px',
		'--shop-header-toggle-right': '52px',
		

		'--univers-font-labeur-title': '9vw',
		'--univers-font-labeur-rgl': '3.2vw',
		'--univers-font-labeur-small': '2.8vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '2vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1.3vw',
		'--univers-store-font-bold': '1.6vw',

		'--univers-thumb-workshop-width' : '20vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)
}

const setDesktopLandscapeBigger = function (){

	// console.log('setDesktopLandscapeBigger')
	// console.log(window.innerWidth)

	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '2.24vw',
		'--card-articles-font-text': '1.1vw',

		'--header-title-font': '9vw',
		'--header-filter-font': '1.5vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2.2vw',
		'--cartouche-font-paratexte': '1.2vw',

		'--article-font-text': '1.4vw',
		'--article-font-paratexte': '1vw',
		'--article-font-notes': '1vw',
		'--article-font-legende': '1vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		// '--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-thumbs-width': '10vw',
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '60%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '50vw', // landscape
		'--redirect-font-title': '3.5vw',
		'--redirect-font-text': '1.4vw',
		'--redirect-title-height': '4.2vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '40vw',
		'--home-case-height-video' : '50vw',
		'--home-font-case1-title': '8vw',
		'--home-font-case1-text': '1.3vw',
		'--home-font-case2-title': '5.5vw',
		'--home-font-case2-text': '1.3vw',
		'--home-font-case3-title': '14vw',
		'--home-font-case3-text': '5vw',
		'--home-height-case-newsletter': '3.4vw',
		'--home-font-case-newsletter': '2.7vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.1vw',
		'--shop-font-labeur-subtitle' : '1.2vw',
		'--shop-font-labeur-title': '2.4vw',
		'--shop-font-bold': '2.4vw',
		'--shop-font-small': '1vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '20px',

		// '--shop-menu-header-height' : '60px',
		'--shop-menu-header-height' : '80px',
		'--shop-menu-header-font' : '62px',
		'--shop-menu-redirect-height': '120px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '44px',
		'--shop-header-toggle-right': '52px',

		'--univers-font-labeur-title': '8vw',
		'--univers-font-labeur-rgl': '2.8vw',
		'--univers-font-labeur-small': '2.4vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '1.5vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1vw',
		'--univers-store-font-bold': '1.2vw',

		'--univers-thumb-workshop-width' : '20vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)

}


const setDesktopLandscapeBiggest = function (){

	// console.log('setDesktopLandscapeBiggest')
	// console.log(window.innerWidth)

	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '2.24vw',
		'--card-articles-font-text': '1.1vw',

		'--header-title-font': '9vw',
		'--header-filter-font': '1.5vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2vw',
		'--cartouche-font-paratexte': '1vw',

		'--article-font-text': '1.25vw',
		'--article-font-paratexte': '1vw',
		'--article-font-notes': '1vw',
		'--article-font-legende': '1vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		// '--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-thumbs-width': '10vw',
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '56%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '50vw', // landscape
		'--redirect-font-title': '3.5vw',
		'--redirect-font-text': '1.4vw',
		'--redirect-title-height': '4.2vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '40vw',
		'--home-case-height-video' : '50vw',
		'--home-font-case1-title': '8vw',
		'--home-font-case1-text': '1.3vw',
		'--home-font-case2-title': '5.5vw',
		'--home-font-case2-text': '1.3vw',
		'--home-font-case3-title': '14vw',
		'--home-font-case3-text': '5vw',
		'--home-height-case-newsletter': '3.2vw',
		'--home-font-case-newsletter': '2.5vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.1vw',
		'--shop-font-labeur-subtitle' : '1.2vw',
		'--shop-font-labeur-title': '2.4vw',
		'--shop-font-bold': '2.4vw',
		'--shop-font-small': '1vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '20px',

		// '--shop-menu-header-height' : '60px',
		'--shop-menu-header-height' : '80px',
		'--shop-menu-header-font' : '62px',
		'--shop-menu-redirect-height': '120px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '44px',
		'--shop-header-toggle-right': '52px',

		'--univers-font-labeur-title': '8vw',
		'--univers-font-labeur-rgl': '2.8vw',
		'--univers-font-labeur-small': '2.4vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '1.5vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1vw',
		'--univers-store-font-bold': '1.2vw',

		'--univers-thumb-workshop-width' : '20vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)

}

const setDesktopLandscapeMax = function (){

	// console.log('setDesktopLandscapeMax')
	// console.log(window.innerWidth)
	let variablesObj = {
		'--page-width': getVar().pageWidth,
		'--marge-page': getVar().margePage,
		'--marge-article': getVar().margeArticle,
		'--esp-small': getVar().espSmall,
		'--esp-med': getVar().espMed,
		'--esp-big': getVar().espBig,

		'--card-articles-font-title': '2.1vw',
		'--card-articles-font-text': '1vw',

		'--header-title-font': '9vw',
		'--header-filter-font': '1.5vw',

		'--cartouche-font-title': '7vw',
		'--cartouche-font-text': '2vw',
		'--cartouche-font-paratexte': '1vw',

		'--article-font-text': '1.1vw',
		'--article-font-paratexte': '0.8vw',
		'--article-font-notes': '0.8vw',
		'--article-font-legende': '0.8vw',

		'--article-viewer-width': getVar().articleViewerWidth,
		// '--article-thumbs-width': getVar().articleThumbsWidth,
		'--article-thumbs-width': '9vw',
		'--article-maintexte-marge': getVar().margeArticleTablet,
		'--article-maintexte-width': '53%',

		// '--redirect-height': '65vw', // portrait
		'--redirect-height': '50vw', // landscape
		'--redirect-font-title': '3.5vw',
		'--redirect-font-text': '1.4vw',
		'--redirect-title-height': '4.2vw',
		'--redirect2-font-title': '6vw',

		'--home-case-height' : '40vw',
		'--home-case-height-video' : '50vw',
		'--home-font-case1-title': '8vw',
		'--home-font-case1-text': '1.3vw',
		'--home-font-case2-title': '5.5vw',
		'--home-font-case2-text': '1.3vw',
		'--home-font-case3-title': '14vw',
		'--home-font-case3-text': '5vw',
		'--home-height-case-newsletter': '3vw',
		'--home-font-case-newsletter': '2.3vw',

		'--menu-header-height': '60px',
		'--menu-header-font': '48px',

		'--search-font-input': '5vw',
		'--search-font-keyword': '4vw',

		'--shop-font-labeur': '1.1vw',
		'--shop-font-labeur-subtitle' : '1.1vw',
		'--shop-font-labeur-title': '2.4vw',
		'--shop-font-bold': '2.4vw',
		'--shop-font-small': '1vw',
		'--shop-font-card-heavy': '6vw',
		'--shop-font-redirect': '20px',

		// '--shop-menu-header-height' : '60px',
		'--shop-menu-header-height' : '80px',
		'--shop-menu-header-font' : '62px',
		'--shop-menu-redirect-height': '120px',
		'--shop-product-slider-height': '80vh',
		'--shop-header-toggle-top': '44px',
		'--shop-header-toggle-right': '52px',

		'--univers-font-labeur-title': '8vw',
		'--univers-font-labeur-rgl': '2.8vw',
		'--univers-font-labeur-small': '2.4vw',
		'--univers-font-title': '60px',
		'--univers-font-date': '1.5vw',
		'--univers-case-height': '70vh',
		'--univers-slider-height': '50vh',
		'--univers-store-font-rgl': '1vw',
		'--univers-store-font-bold': '1.2vw',

		'--univers-thumb-workshop-width' : '20vw',
		'--univers-thumb-event-width': '30vw'
	}
	setVars(variablesObj)

}

/* _________ _________ _________ _________ _________ _________ _________ _________*/

function getRandom(max){
	return Math.floor(Math.random() * Math.floor(max));
}

export default {
	init: () => {
		return { 
			readCookie,
			setResizeObserver, setResizeObserverWithReturn, toggleResizeObserver,
			shopToggleHide, shopToggleAppear, shopCartAppear, shopCartHide,
			getArticleCategory, getContentMenu,
			setColors, setColorsArticle, getLightenColor,
			getVar, setVariables, setTabletVariables, setMobileVariables,
			setDesktopLandscapeSmall, setDesktopLandscapeMed, setDesktopLandscapeBig, 
			setDesktopLandscapeBigger, setDesktopLandscapeBiggest, setDesktopLandscapeMax,
			getRandom
		}
	}
}






