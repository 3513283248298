// HTTP Basic auth headers

const auth = btoa('user@api.com' + ':' + 'adminadmin')
const myHeaders = { Authorization: 'Basic ' + auth }

let tk
tk
let apiUrl
let apiLang 


const getSite = async () => {
	
  // let pageUrl = apiUrl + '/env.json'  
  let pageUrl = apiUrl + apiLang + '/env.json'

  // console.log(apiLang, pageUrl)

  const resp = await fetch(pageUrl, myHeaders)
  const home = await resp.json()

  tk = home.tk

  return home
}

const getPageRouter = async (page, whatArticle) => {
	// console.log(page)
	let response 
	
	if ( page === 'home' || page === 'decouvertes' || page === 'boutique'){
		response = getPage(page)
	}

	if ( page === 'recherche'){
		let query = { Init: true }
		response = getPageWithHash('recherche', query)
	}

	if ( page === 'art' || page === 'amateur'){
		let query = { Name: page }
		response = getPageWithHash('articles', query)
	}

	if ( page === 'article' || page === 'product'){
		let query = { Name: whatArticle }
		response = getPageWithHash(page, query)
	}

	return response
}

const getPage = async (endpoint) => {
		
	let newEndpoint = endpoint == 'univers' ? 'decouvertes' : endpoint

	// let pageUrl = apiUrl + apiLang + '/' + newEndpoint + '.json?Token=' + tk 
	let pageUrl = apiUrl + apiLang + '/' + newEndpoint + '.json'
	// console.log(pageUrl)

	const resp = await fetch(pageUrl, myHeaders)
	const page = await resp.json()

	return page
}


const getPageWithHash = async (endpoint, params) => {
	
	// let testUrlWithFunction = apiUrl + '/' + endpoint + '.json'
	let pageUrl = apiUrl + apiLang + '/' + endpoint + '.json'

	let newpPageUrl = setUrlWithHash(pageUrl, params)
	// console.log(newpPageUrl)

	const resp = await fetch(newpPageUrl, myHeaders)
	const page = await resp.json()

	return page
}

function setUrlWithHash(root, obj){

	let i = 0
	let arrayParams = [];
	for (const element in obj){
		let addUrl = `${element}=${obj[element]}`
		arrayParams[i] = addUrl
		i++
	}
	let stringParams = arrayParams.join('&')
	let newUrl = root + '?' + stringParams

	return newUrl
}

const getArticles = async (params) => {

	let pageUrl = apiUrl + apiLang + '/getarticles.json'

	let newpPageUrl = setUrlWithHash(pageUrl, params)
	// console.log(newpPageUrl)

	const resp = await fetch(newpPageUrl, myHeaders)
	const page = await resp.json()


	console.log(newpPageUrl)

	return page
}


export default {
	init: (url, lang) => {

		apiUrl = url
		apiLang = lang


		return { getPageRouter, getSite, getPage, getPageWithHash, getArticles, apiUrl, apiLang }
	}
}








