<template>

  <div>

    <transition name="fade">

    <div
    class="Menu--case"
    :class="menuCaseClass ? menuCaseClass: 'Menu--case'"
    :style=" { 'background-color': color, 'z-index': whatzIndex ? '10' : '1' , 
    top: 'calc( ( (100vh - ' + mobileHeaderHeight () + ' ) / 6) * '+ this.index + ' + ' + mobileHeaderHeight () + ' )' } "

    v-if="caseDisplay"
    :data-index ="index"

    @mouseenter.stop="mouseEnter"
    @mouseleave.stop="mouseLeave"
    @click="testclick"
    >
    <!-- top: 'calc(' + caseHeight() + ' + ' + mobileHeaderHeight () + ')' } " -->
    
      <!-- <slot></slot> -->

      <slot name="title"></slot>
      <slot name="info"></slot>
    </div>


  </transition>

  </div>

</template>

<script>

export default {
  name: 'MenuCase',
  props: ['index', 'uid', 'color', 'whatzIndex', 'menuCaseClass', 'isMobile', 'caseDisplay'],
  data: function(){
    return {

    }
  },
  cumputed:{

  },
  methods: {
    caseHeight: function(){
      let height 
      if (this.menuCaseClass == 'MenuMobile--case'){
        height = (95 / 6) * this.index + 'vh'

      }else {
        height = (100 / 6) * this.index + 'vh'
      }
      // console.log(height)
      return height
    },
    mobileHeaderHeight: function(){
      if (this.isMobile){
        return 'var(--menu-header-height)'
      }else{
        return '0vw'
      }

    },

    testclick: function(){
      this.$emit('testclick', this.index)
    },

    mouseEnter: function(){
      this.$emit('mouse-enter', this.index)
    },

    mouseLeave: function() {
      this.$emit('mouse-leave', this.index)
    }

  },
  created(){
  }
}
</script>

<style lang="scss">

// .fade-enter-active, .fade-leave-active{
//   // transition: opacity 0.7s;
//   transition: transform 0.5s;
// }

// .fade-enter, .fade-leave-active{
//   // opacity: 0;
//   transform: translate(-100%);
// }



.MenuCase{



}



</style>
