import Vue from 'vue';

const LazyLoading = {
	inserted(el) {

    let callbackImage = function(entries, observer){
			entries.forEach(entry => {
				observer

				if (entry.isIntersecting) {
					// setTimeout(function(){ el.srcset = el.dataset.srcset }, 1000);
					el.srcset = el.dataset.srcset
          // console.log(entry.isIntersecting)
					observerImage.unobserve(el)
        }

      })
    }
    
    let observerImage = new IntersectionObserver(callbackImage, {root: null, rootMargin: '0px', threshold: 0.1});

    observerImage.observe(el)




    // el.srcset = el.dataset.srcset
  }

};

export default {
  LazyLoading,
};

// Make it available globally.
Vue.directive('lazyloading', LazyLoading);