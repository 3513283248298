<template>

	<router-link 
	v-bind:to=" '/' + $route.params.language + '/' + content.uid "
	class="HomepageArticle" 
	:style="{'background-color': whatColor, 'flex-direction': whatDirection}"
	:class="whatDirection"
	>
		<!-- <img class="HomepageArticle--img-container" :alt="content.title" :src="content.cover.url" :srcset="content.cover.srcset" sizes="30vw"> -->
		<div class="HomepageArticle--img-container" :class="whatDirection">
		<img class="HomepageArticle--img-container--img" 
		v-lazyloading
		:alt="content.subtitle_cover" 
		:src="content.covers.portrait.thumb" 
		:data-srcset="content.covers.portrait.srcset" 
		sizes="30vw">
		</div>

		<div class="HomepageArticle--text-container">
			<!-- <div class="HomepageArticle--text-container--title"> {{ content.title }} </div> -->
			<div class="HomepageArticle--text-container--title" v-html="content.title"></div>
			<div class="HomepageArticle--text-container--text" v-html="content.subtitle_cover"></div>
		</div>	
	</router-link>

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'HomepageArticle',
  props: ['content', 'index'],
	directives:{
		LazyLoading
  },
  computed: {
	whatColor: function(){
		let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
		let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
		return this.content.category === 'art' ? artColor : amateurColor; 
  },
	whatDirection: function(){
		if(this.index % 2 === 0){
			return 'row-reverse'
		}else{
			return 'row'
		}
    }
  },
  created(){
	// console.log(this.content.weight)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.HomepageArticle{
	background-color: var(--first-color);
	width: 100%;
	height: var(--home-case-height);
	display: flex;
	z-index: 2;

	overflow: hidden;

	&--img-container{
		height: 100%;
		max-width: 45%;
		width: 100%;
		overflow: hidden;
		// background-color: red;

		&--img{
			height: 100%;	
			width: 100%;
			object-fit: cover;	
		}
	}

	&--text-container{
		// margin: var(--esp-med);
		width: 100%;
		// max-width: 55%;
		// background-color: green;
		// overflow: visible;

		&--title{
			font-size: var(--home-font-case1-title);
			line-height: 0.9;



			// word-break: break-word;
			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}

			line-clamp: 4;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;  
			
			overflow: hidden;

			padding-top: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);
			// background-color: green;

			// overflow: visible;
		}

		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case1-text);

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-bottom: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);

			& p {

				& em {
					font-family: 'vd-reg';
				}
			}
			
		}
	}
}

.HomepageArticle.row{
	justify-content: left;
}

.HomepageArticle--img-container.row{
	// overflow: hidden;
	
	
}

.HomepageArticle.row-reverse{
	justify-content: space-between;
}




</style>