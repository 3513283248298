<template>

	<router-link 
	v-bind:to=" '/' + $route.params.language + '/' + content.uid "
	class="HomepageArticleDouble" 
	:style="{'background-color': whatColor}"
	:class="whatDirection">

		<img class="HomepageArticleDouble--img-container"
		v-lazyloading 
		:alt="content.subtitle_cover" 
		:src="content.covers.landscape.thumb" 
		:data-srcset="content.covers.landscape.srcset"
		sizes="25vw">

		<div class="HomepageArticleDouble--text-container">
			<div class="HomepageArticleDouble--text-container--title" v-html="content.title"></div>
			<div class="HomepageArticleDouble--text-container--text" v-html="content.subtitle_cover"></div>
		</div>	
	</router-link>

</template>

<script>

export default {
  name: 'HomepageArticleDouble',
  props: ['content', 'index'],
  computed: {
	whatColor: function(){
		let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
		let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
		return this.content.category === 'art' ? artColor : amateurColor; 
  },
	whatDirection: function(){
		if(this.index % 2 === 0){
			return 'row-reverse'
		}else{
			return 'row'
		}
    }
  },
  created(){
	// console.log(this.content.weight)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';
// .HomepageArticleDouble{
// 	background-color: var(--first-color);
// 	width: 50%;

// 	height: 80vh;

// 	display: flex;
// 	// flex-wrap: wrap-reverse;
// 	flex-wrap: wrap;

// 	// justify-content: flex-start;
// 	justify-content: space-evenly;
// 	// 

// 	&--img-container{

// 		width: 100%;
// 		// width: auto;
// 		height: 60%;
// 		object-fit: cover;
// 	}

// 	&--text-container{

// 		font-size: 5vw;
// 		align-self: flex-start;

// 		&--title{

// 		}

// 		&--text{
// 			font-size: 2vw;

// 		}
// 	}
// }

.HomepageArticleDouble{
	background-color: var(--first-color);
	width: calc( (100vw - var(--marge-page) - var(--esp-med)) / 2 );

	height: var(--home-case-height);
	z-index: 2;

	&--img-container{
		width: 100%;
		// width: auto;
		max-height: 60%;
		object-fit: cover;
	}

	&--text-container{
		font-size: 5vw;
		align-self: flex-start;

		// margin: var(--esp-med);

		&--title{
			font-size: var(--home-font-case2-title);
			line-height: 0.9;

			// word-break: break-word;
			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}

			line-clamp: 2;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-top: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);
		}
		// Dans la maison de campagne et de curiosités de l'artiste designer Frédérique Morrel
		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case2-text);

			line-clamp: 3;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;  
			overflow: hidden;

			padding-bottom: var(--esp-med);
			padding-left: var(--esp-med);
			padding-right: var(--esp-med);

			& p {

				& em {
					font-family: 'vd-reg';
				}
			}
		}
	}
}

.HomepageArticleDouble{

	&.row{
		display: inline-block;
	}

	&.row-reverse{
	display: flex;
	flex-wrap: wrap-reverse;
	}

	&--text-container{
		// align-self: flex-end;
		align-self: stretch;
	}
}

@media (orientation: portrait) { 
.HomepageArticleDouble{
	width: calc( (100% - var(--esp-med)) / 2 );
}
}







</style>