<template>
	<router-link 
	v-bind:to=" '/' + $route.params.language + '/decouvertes' "
	class="HomepageAnnonceEvent" 
	@mouseenter.native="changeSide"
	@mouseleave.native="mouseleave">	

		<div class="HomepageAnnonceEvent--text-container">
			<div class="HomepageAnnonceEvent--text-container--title"> {{ content.text }} </div>
			<div class="HomepageAnnonceEvent--text-container--text" v-html="content.text2"></div>
		</div>

<!-- 		<transition name="fade-annonce-event" appear>

		<img class="HomepageAnnonceEvent--img-container" 
		v-if="isClicked"
		:alt="content.title" 
		:src="content.cover.url" 
		:data-srcset="content.cover.srcset" 
		:style="{height: whatHeight + 'px'}"
		sizes="60vw">

		</transition> -->

		<div class="HomepageAnnonceEvent--img-container">

		<transition name="fade-annonce-event" appear>

		<img class="HomepageAnnonceEvent--img-container--img" 
		v-if="isClicked"
		:alt="content.title" 
		:src="content.cover.url" 
		:data-srcset="content.cover.srcset" 
		:style="{height: whatHeight + 'px'}"
		sizes="60vw">

		</transition>

		</div>
		
	</router-link>
</template>

<script>

export default {
  name: 'HomepageAnnonceEvent',
  props: ['content'],
	data: function(){
		return {
		isClicked : false,

		imgHeight: ''
	}
  },
  computed: {
		whatHeight: function(){
			return this.imgHeight
		}
  },
	methods:{
		changeSide: function(){
			
			this.isClicked = true
		},
		mouseleave: function(){
			this.isClicked = false
		}
	},
	mounted(){

    this.$tools.setResizeObserver(this.$el, () => {
			this.imgHeight = this.$el.getBoundingClientRect().height
    })

	}
}
</script>

<style lang="scss">
@import '@/css/variables';


.fade-annonce-event-enter-active, .fade-annonce-event-leave-active{
  transition: opacity 0.5s ease;
}
.fade-annonce-event-enter, .fade-annonce-event-leave-active{
  opacity: 0
}


.HomepageAnnonceEvent{
	width: 100%;
	background-color: var(--third-color);
	z-index: 2;
	position: relative;

	overflow: hidden;

	&--text-container{

		text-align: center;
		vertical-align: middle;

		margin-top: var(--esp-big);
		margin-bottom: var(--esp-big);
		margin-left: var(--esp-big);
		margin-right: var(--esp-big);

		z-index: 10;
		position: relative;

		&--title{
			font-size: var(--home-font-case3-title);
			line-height: 0.9;
			word-break: break-word;

			z-index: 10;
			position: relative;
		}

		&--text{
			font-size: var(--home-font-case3-text);

			z-index: 10;
			position: relative;
		}
	}

	// &--img-container{
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	object-fit: cover;

	// 	top:0;
	// 	left:0;
		
	// 	z-index:1;
	// }

	&--img-container{

		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;

		top:0;
		left:0;
		
		z-index:1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

		&--img{
			// object-fit: cover;
			// max-width: 80%;
			// max-height: 80%;

			width: 100%;
			height: 100%;
			object-fit: cover;

			z-index:1;
		}

	}
}

</style>