<template>
  <div v-letterTitle>

    <div class="Menu--case--left" :style="{'color': index == 5? 'white': '' }">
      <div 
      class="Menu--case--left--element"
      :class="isVisible[index] ? 'notVisible': '' "
      v-for="(v, index) in title" 
      :key="index"> 
        {{v}} 
    </div>

    </div>

    <div class="Menu--case--right" :style="{'color': index == 5? 'white': ''}"> {{content}} </div>

  </div>
</template>

<script>
export default {
  name: 'MenuCaseContent',
  props: ['index', 'content', 'color'],
  data: function(){
    return {
      title: "Profane".split(''),

      arrayTitle: [],

      caseLeftWidth: '',
      caseRightWidth: '',
      caseLeftElementWidth: [],

    }
  },
  directives: {
    letterTitle:{
      inserted: function(el, binding, vnode){

        let setData = () => {

          let ArrayCaseChildren = Array.prototype.slice.call(el.children)

          let caseLeftWidth = ArrayCaseChildren[0].getBoundingClientRect()
          let caseRightWidth = ArrayCaseChildren[1].getBoundingClientRect()

          vnode.context.caseLeftWidth = caseLeftWidth
          vnode.context.caseRightWidth = caseRightWidth

          let caseLeftChildren = Array.prototype.slice.call(ArrayCaseChildren[0].children)

          caseLeftChildren.forEach((element) => {
            let elementWidth = element.getBoundingClientRect().right
            vnode.context.caseLeftElementWidth.push(elementWidth)
          })

          vnode.context.calcul() 
        }

        // setTimeout(function(){ 
        //   setData()
        //   window.addEventListener('resize', setData)
        // }, 100);
        
        // el.$destroy = () => window.removeEventListener('resize', setData)

        let resizeData = () => {
          setData()
          setTimeout(function(){ setData() }, 350)
        }

        setTimeout(function(){ 
          setData()
          window.addEventListener('resize', resizeData)
        }, 100);

        el.$destroy = () => window.removeEventListener('resize', resizeData)

      },
      unbind: function(el){
        el.$destroy()
      }
    }
  },
  computed:{
    isVisible: function(){
      return this.arrayTitle.map(function(item){
        return item
      })
    }
  },
  methods: {
    calcul: function(){
      let security = window.innerWidth * 3 / 100 

      this.caseLeftElementWidth.forEach((element, index) => {

        if (element + security > this.caseRightWidth.left){
          this.$set(this.arrayTitle, index, false)
        }else {
          this.$set(this.arrayTitle, index, true)
        } 
      })
    }
  },
  created(){
    this.title.forEach((element, index) => {
      this.$set(this.arrayTitle, index, false)
    })
  }
}
</script>

<style lang="scss">

.Menu--case--left{

  display: flex;

  &--element{
    opacity: 0;

    &.notVisible{
      opacity: 1;
    }
  }
}



</style>
