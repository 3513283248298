export default {

	data: function(){
		return {
			stateClient: this.$client.state,
		}
	},

	computed: {
		ClientWidth: function(){ return this.stateClient.scale },
		ClientFormat: function(){ return this.stateClient.format},
		ClientMenuFormat: function(){ return this.stateClient.menuFormat },
		ClientDevice: function(){ return this.stateClient.device },
		whatTransition: function(){ return this.stateClient.viewTransition },

		isKeepAlive: function(){
			return this.stateClient.isKeepAlive
		},



    whatComputConfig: function(){

    if (this.ClientFormat == 'portrait'){

      if (this.ClientWidth == 'mobileWidth'){
        return '-mobile'
      }else {
        return '-portrait'
      }
      
    }else {
      return ''
    }

    },
	}
	
}