<template>
  <div id="app">

    <!-- background-menu pour transition site -> shop -->
    <template v-if="whatConfig == 'landscape-app'">
    <transition name="BackgroundMenuInit" appear>

    <div class="Menu--background">
      <div class="Menu--background--case"
      :class="whatClass(index)" 
      v-for="(i, index) in 6" :key="i">
      </div>
    </div>

    </transition>

    </template>


    <PopCookie v-if="whatPopCookie" @cookie-validate="setCookie()"></PopCookie>

    <!-- menu principal Menu/MenuMobile -->
    <component 
    v-if="isMenu"
    :is="whatMenu" 
    :ClientFormat="ClientFormat" 
    :ClientDevice="ClientDevice" 
    :ClientMenuFormat="ClientMenuFormat" 
    :contentMenu="contentMenu" 
    ></component>

    <!-- product -> Menu Redirect -->
    <RedirectShopApp v-if="isRedirectShopApp"></RedirectShopApp>


<!--     <router-view  
    v-if="ClientFormat == 'portrait' && this.ClientWidth == 'mobileWidth' && $route.name === 'article'" 
    :key="$route.fullPath"
    name="mobile">
    </router-view>

    <router-view 
    v-else
    :key="$route.fullPath">
    </router-view> -->


    <template v-if="ClientFormat == 'portrait' && this.ClientWidth == 'mobileWidth' && $route.name === 'article'">
      <router-view  
      :key="$route.fullPath"
      name="mobile">
      </router-view>
    </template>
    
<!--     <template v-else>
      <keep-alive max="1" include="About">
      <router-view 
      :key="$route.fullPath">
      </router-view>
      </keep-alive>
    </template> -->

    <template v-if="!(ClientFormat == 'portrait' && this.ClientWidth == 'mobileWidth' && $route.name === 'article') && !(isKeepAlive)">

      <router-view 
      :key="$route.fullPath">
      </router-view>

    </template>

    <template v-if="!(ClientFormat == 'portrait' && this.ClientWidth == 'mobileWidth' && $route.name === 'article') && isKeepAlive">

      <keep-alive max="1" include="About">
      <router-view 
      :key="$route.fullPath">
      </router-view>
      </keep-alive>

    </template>

  </div>
</template>

<script>
import Menu from '@/components/menu/Menu.vue'
import MenuTablet from '@/components/menu/MenuTablet.vue'
import MenuMobile from '@/components/menu/MenuMobile2.vue'

import PopCookie from '@/components/boutique/PopCookie.vue'

import RedirectShopApp from '@/components/boutique/headershop/RedirectShopApp.vue'

import clientConfigs from '@/mixins/clientConfigs.js'


export default {
  name: 'App',
  mixins: [clientConfigs],
  data: function(){
    return {
      initSession: true,
      isMenu: false,
      isRedirectShopApp: false,

      cookieEnable: ''
    }
  },
  components: {
    Menu,
    MenuTablet,
    MenuMobile,
    RedirectShopApp,
    PopCookie
  },
  computed:{
    langage: function(){
      return this.$route.params.language
    },
    contentMenu: function(){ 
      return this.$tools.getContentMenu(this.$route.params.language, this.$site.menu)
    },
    whatConfig: function(){
      if (this.ClientFormat == 'landscape'){
        return 'landscape-app'
      }else {
        return ''
      }
    },
    whatMenu: function(){
      let params = {
        mobile: 'MenuMobile',
        tablet: 'MenuMobile',
        desktopLandscape: 'Menu',
        desktopPortraitMobile: 'MenuMobile',
        desktopPortraitNotMobile: 'MenuMobile',
        desktopLandscapeSmall: 'Menu',
        desktopLandscapeMed: 'Menu',
        desktopLandscapeBig: 'Menu',
        desktopLandscapeBigger: 'Menu',
        desktopLandscapeBiggest: 'Menu',
        desktopLandscapeMax: 'Menu'
      }
      return this.$client.getConditionnal(params)
    },

    whatPopCookie : function(){

      let returnCookie

      if (this.$route.name === 'boutique' || this.$route.name === 'product'){

        if (this.cookieEnable == true){
          returnCookie = false
        }

        if (this.cookieEnable == ''){
          returnCookie = true
        }

        if (this.cookieEnable == null){
          returnCookie = true
        }

      }else {
        returnCookie = false
      }

      // console.log(returnCookie)

      return returnCookie
    }
  },
  methods:{

    setCookie: function(){

      let cookieValue = this.$tools.readCookie('cookieEnable')

      if (cookieValue){ // cookie deja present true/false

        this.cookieEnable = Boolean(cookieValue)
        // console.log(this.cookieEnable)
        
      } else {

        // console.log('cookieEnable non défini')
        
      }

    },

    whatClass: function(index){
      return 'case' + index
    },

    setIsMenu: function(val){
    // Active/désactive le menu pour view boutique et product (portrait et landscape)

      if (val == 'boutique' || val == 'product'){

        if (this.ClientWidth == 'mobileWidth'){
          this.isMenu = true

        }else {
          this.isMenu = false
        }

      }else if (val == 'home' || val == 'art' || val == 'amateur' || val == 'recherche' || val == 'decouvertes' || val == 'article'){
        this.isMenu = true
      }

      if (val == 'cgv'){

        this.isMenu = false

      }

    },
    setIsRedirectShopApp: function(val){

      if (this.ClientWidth == 'mobileWidth'){
        this.isRedirectShopApp = false

      }else {

        if (val == 'product'){
          this.isRedirectShopApp = true

        }else {
          this.isRedirectShopApp = false
        }
      }
    },

    setSecurity: function(){
      this.$el.classList.add('visible')

      window.addEventListener('keydown', (e) => {

        if (e.key == "n"){
          this.$el.classList.remove('visible')
        }
      })

    }

  },
  watch: {
  
    '$route.name': {
      deep: true,
      immediate: true,
      handler(val){
        this.setIsMenu(val)
        this.setIsRedirectShopApp(val)
      }
    },
    ClientWidth: {
      immediate: true,
      handler() {
        // console.log('watcher ClientWidth')
        let params = {
          mobile: this.$tools.setMobileVariables,
          tablet: this.$tools.setTabletVariables,
          desktopLandscape: this.$tools.setVariables,
          desktopPortraitMobile: this.$tools.setMobileVariables,
          desktopPortraitNotMobile: this.$tools.setTabletVariables,
          desktopLandscapeSmall: this.$tools.setDesktopLandscapeSmall,
          desktopLandscapeMed: this.$tools.setDesktopLandscapeMed,
          desktopLandscapeBig: this.$tools.setDesktopLandscapeBig,
          desktopLandscapeBigger: this.$tools.setDesktopLandscapeBigger,
          desktopLandscapeBiggest: this.$tools.setDesktopLandscapeBiggest,
          desktopLandscapeMax: this.$tools.setDesktopLandscapeMax
        }
        this.$client.getConditionnal(params)()
        this.setIsMenu(this.$route.name)
        this.setIsRedirectShopApp(this.$route.name)
      },
    },

    /**
     * 
     */

    ClientFormat: function(){
      // console.log('WATCHER ClientFormat')
      let params = {
        mobile: this.$tools.setMobileVariables,
        tablet: this.$tools.setTabletVariables,
        desktopLandscape: this.$tools.setVariables,
        desktopPortraitMobile: this.$tools.setMobileVariables,
        desktopPortraitNotMobile: this.$tools.setTabletVariables,
        desktopLandscapeSmall: this.$tools.setDesktopLandscapeSmall,
        desktopLandscapeMed: this.$tools.setDesktopLandscapeMed,
        desktopLandscapeBig: this.$tools.setDesktopLandscapeBig,
        desktopLandscapeBigger: this.$tools.setDesktopLandscapeBigger,
        desktopLandscapeBiggest: this.$tools.setDesktopLandscapeBiggest,
        desktopLandscapeMax: this.$tools.setDesktopLandscapeMax
      }
      this.$client.getConditionnal(params)()
      this.setIsMenu(this.$route.name)
      this.setIsRedirectShopApp(this.$route.name)
    },


  },

  beforeCreate(){
    
  },

  created(){
    this.setCookie()
  },
  mounted() {
    // this.setSecurity()
    this.$client.setListener()
    this.$tools.setColors(this.$site.colors)
  }
}

</script>

<style lang="scss">

@import '@/css/clean';
@import '@/css/fonts';
@import '@/css/root';
@import '@/css/variables';
@import '@/css/transition';
@import '@/css/snipcart';

#app {
  font-family: oli;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

  &.visible{
    opacity: 0;
    pointer-events: none;
  }
  
}

body{
  position: relative;
  height: 100%;

}
html {
    overflow: scroll;
    scrollbar-width: none;
    height: 100%;
}

.fine{
  // background-color: red;
  letter-spacing: -0.15em;
}

::-webkit-scrollbar {
    width: 0px; 
    height: 0px;
    background: transparent; 
    scrollbar-width: none;
    display: none;
}
::-webkit-scrollbar-thumb {
    background: black;
    scrollbar-width: none;
    display: none;
}



.Menu--background{

  position: fixed;

  height: 100%;
  width: 100vw;
  left: 0;
  z-index: -1;
  pointer-events: none;
  top: 0;

  &--case {
    height: calc(100% / 6 );
    background-color: orange;
    z-index: 1;

    &.case0{
      background-color: white;
    }
    &.case1{
      background-color: var(--first-color);
    }
    &.case2{
      background-color: var(--second-color);
    }
    &.case3{
      background-color: var(--third-color);
    }
    &.case4{
      background-color: var(--fourth-color);
    }
    &.case5{
      background-color: black;
    }
  }
}


</style>




















