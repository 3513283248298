<template>

  <transition 
  appear
  mode="out-in"
  v-on:before-enter="beforeEnter"
  v-on:enter="enter"
  v-on:after-enter="afterEnter"
  v-on:enter-cancelled="enterCancelled"
  v-on:leave="leavee"
  v-bind:css="false">

  <div class="Homepage" 
  :class="[whatConfig, whatMarginBottom]"
  :style="{height: whatHeight()}">

      <Scrollbar v-if="this.whatComputConfig != '-mobile'" :parent="'homepage'"></Scrollbar>

      <!-- background-menu pour v.portrait -> shop -->    
      <template v-if="whatConfig == 'portrait-homepage'">

          <div class="Homepage--background"
          :style="{height: whatHeight()}">
            <div class="Homepage--background--case"
            :class="whatClass(index)" 
            v-for="(i, index) in 6" :key="i">
            </div>
          </div>

        </template>

      <component 
      :is="whatBloc(bloc.type, bloc.hasVideo, bloc.weight, index)" 
      v-for="(bloc, index) in page.content" 
      :key="index"
      :content="bloc"
      :index="index"
      :clientWidth="ClientWidth">
      </component>

      <template v-if="page.newsletter_end.isActive == 'true'">

        <HomeNews :clientWidth="ClientWidth"></HomeNews>

      </template>

  </div>

</transition>

</template>

<script>
import AnnonceEvent from '@/components/homepage/AnnonceEvent.vue'
import AnnonceShop from '@/components/homepage/AnnonceShop.vue'
import Article from '@/components/homepage/Article.vue'
import ArticleDouble from '@/components/homepage/ArticleDouble.vue'
import ArticleNews from '@/components/homepage/ArticleNews.vue'
import ArticleVideo from '@/components/homepage/ArticleVideo.vue'
import ArticleMobile from '@/components/homepage/ArticleMobile.vue'
import ArticleMobileNews from '@/components/homepage/ArticleMobileNews2.vue'
import HomeNews from '@/components/homepage/HomeNews.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'Home',
  mixins: [clientConfigs],
  data: function(){
    return {
      state : this.$store.state,
      loadedData: false,
      articleCount: 0
    }
  },
  components: {
    AnnonceEvent,
    AnnonceShop,
    Article,
    ArticleDouble,
    ArticleVideo,
    ArticleMobile,
    ArticleMobileNews,
    Scrollbar,

    ArticleNews,
    HomeNews
  }, 
  computed: {
    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },

    whatConfig: function(){
      if (this.ClientFormat == 'portrait'){
        return 'portrait-homepage'
      }else {
        return ''
      }
    },

    whatMarginBottom: function(){

      if (this.ClientFormat == 'portrait'){
        
        if ( this.ClientWidth == 'mobileWidth'){
          return 'portrait-mobile-margin' 

        }else {
          return 'portrait-not-mobile-margin'
        }

      }else {
        return ''
      }
    },

    whatNewsletterPosition: function(){
      if (this.page.newsletter.isActive == 'true'){
        return Number(this.page.newsletter.position)
      }else {
        return ''
      }
    }
  },

  methods: {
    whatHeight(){
      // if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
       
      //   let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');

      //   let pixelTotalHeight = window.innerHeight 
      //   let pixelMargeHeight = margeArticleRaw.split('px')[0]
      //   let pixelContentHeight = window.innerHeight - pixelMargeHeight

      //   let pourentContentHeight = pixelContentHeight * 100 / pixelTotalHeight

      //   return pourentContentHeight + '%'

      // }else {
      //   return ''
      // }

      return ''
    },

    whatBloc: function(val, hasVideo, weight, index){
      let whatComponent 

      if (val == 'annonce'){
        whatComponent = 'AnnonceEvent'
      }

      if(val == 'shop'){
        whatComponent = 'AnnonceShop'
      }

      if ( this.ClientDevice == 'mobile' || this.ClientDevice == 'desktop' && this.ClientFormat == 'portrait' && this.ClientWidth == 'mobileWidth'){

        if (val == 'annonce'){
          whatComponent = 'AnnonceEvent'

        }else if(val == 'article'){

          if ( this.whatNewsletterPosition == index ){
            // whatComponent = 'ArticleMobileNews'
            whatComponent = 'ArticleMobileNews'
          }else {
            whatComponent = 'ArticleMobile'
          }
        }

      }else { 

        if (val == 'article'){

          if (hasVideo == 'true'){
            whatComponent = 'ArticleVideo'
          }else if (weight == "0.5"){
            whatComponent = 'ArticleDouble'
          }else {
            if ( this.whatNewsletterPosition == index ){
              whatComponent = 'ArticleNews'
            }else {
               whatComponent = 'Article'
            }
          }
        }
      }

      return whatComponent
    },

    whatClass: function(index){
      return 'case' + index
    },

    beforeEnter: function (el) {
      el.classList.add('before-enter')
    },
    enter: function (el, done) {
      // console.log(this.$client.isInit)
      // console.log('enter')
      let timing = this.$client.isInit == true ? 100:1800
      setTimeout(() => { done() }, timing);
    },
    afterEnter: function (el) {
      el.classList.remove('before-enter')
      el.classList.add('enter-active') 
      setTimeout(() => { el.classList.remove('enter-active') }, 800);
    },
    enterCancelled: function (el) {
      el.classList.remove('before-enter')
      el.classList.remove('enter-active')
    },

    beforeLeave: function(el){
      console.log('beforeLeave')
      el.classList.add('leave-active')
      el.classList.add('before-leave')
    },

    leavee: function (el, done) {

      // console.log('leave')

      el.classList.add('leave-active')
      el.classList.add('before-leave')
      el.classList.add('leave-to')

      setTimeout(() => { done() }, 450);

    },  
    afterLeave: function(el){
      console.log('afterLeave')
      el.classList.remove('leave-to')
      el.classList.remove('leave-active')
      el.classList.remove('before-leave')
    },

    leaveCancelled: function(el){
      console.log('leaveCancelled')
      el.classList.remove('leave-to')
      el.classList.remove('leave-active')
      el.classList.remove('before-leave')  
    },

    onResize: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        // console.log('HOME onResize forceupdate-mobile')
        this.$forceUpdate();
      }
    }
  },

  created() {
    window.scroll(0, 0);
    window.addEventListener('resize', this.onResize)

    // console.log(this.page)
  },
  
  mounted(){
    this.$client.isInit = true
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}

</script>

<style lang="scss">
@import '@/css/variables';
@import '@/css/transition';

.before-enter{
  top: 100vh;
}
.enter-active{
  transition: top 0.6s;
  top: 0vh;
}
.before-leave{
  opacity: 1;
}
.leave-to{
  opacity: 0;
}
.leave-active{
  transition: opacity 0.3s;
}


.Homepage{

  z-index: 2;

  position: absolute;

  width: $page-width;
  left: calc(var(--marge-page) / 2);

  background-color: rgba(255,255,255,0);

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & a {
    margin-top: var(--esp-med);
  }

  & a:last-child {
    margin-bottom: var(--esp-med);
  }

  // & div {
  //   // margin-top: var(--esp-med);
  // }

  // & div:last-child {
  //   margin-bottom: var(--esp-med);
  // } 
}

.Homepage.isLoaded{
  top: 0vh;
  transition: all 0.8s;
}



.Homepage.portrait-homepage{

  z-index: 2;
  position: absolute;
  
  width: calc(100vw - (2 * var(--esp-small)));
  min-height: calc(100% - var(--menu-header-height));

  top: var(--menu-header-height);
  left: var(--esp-small);

  flex-wrap: wrap;
  justify-content: space-between;

  & a {
    margin-top: var(--esp-med);
  }

}


.Homepage.portrait-mobile-margin{

  & div a:last-child {
    margin-bottom: 0;
  }

  & div:last-child a {
    margin-bottom: var(--esp-med);
  }
}

.Homepage.portrait-not-mobile-margin{

  & a:last-child {
    margin-bottom: var(--esp-med);
  }
}



.Homepage--background{

  position: fixed;
  width: 100vw;
  left: 0;
  z-index: 1;
  pointer-events: none;

  height: calc(100% - var(--menu-header-height));
  top: var(--menu-header-height);
  transition: height 0.35s ease;

  &--case {
    height: calc(100% / 6  + 1px );
    z-index: 1;

    &.case0{
      background-color: white;
    }
    &.case1{
      background-color: var(--first-color);
    }
    &.case2{
      background-color: var(--second-color);
    }
    &.case3{
      background-color: var(--third-color);
    }
    &.case4{
      background-color: var(--fourth-color);
    }
    &.case5{
      background-color: black;
    }
  }
}


</style>
