<template>

  <transition :name="whatConfig">

  <router-link
  class="Menu--case"
  :class="menuCaseClass ? menuCaseClass: 'Menu--case'"
  v-bind:to="'/' + $route.params.language + uid"
  :style=" { 'background-color': color, 'z-index': whatzIndex ? '900' : '1' , 
  top: 'calc( ( (100% - ' + mobileHeaderHeight () + ' ) / 6) * '+ this.index + ' + ' + mobileHeaderHeight () + ' )',
  height: whatCaseHeight}"

  v-if="caseDisplay"
  :data-index ="index"

  @mouseenter.native.stop="mouseEnter"
  @mouseleave.native.stop="mouseLeave"
  @click.native="testclick"
  >
  <!-- top: 'calc( ( (100vh - ' + mobileHeaderHeight () + ' ) / 6) * '+ this.index + ' + ' + mobileHeaderHeight () + ' )' } " -->
  
    <slot></slot>

  </router-link>

  </transition>

</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'MenuCase',
  mixins: [clientConfigs],
  props: ['index', 'uid', 'color', 'whatzIndex', 'menuCaseClass', 'isMobile', 'caseDisplay', 'whatCaseHeight'],
  data: function(){
    return {

    }
  },
  watch: {
    menuDisplay: function(val){
      console.log(val)
    }
  },
  computed:{
    whatConfig: function(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
        return 'fadePortrait'
      }else {
        return 'fadeLandscape'
      }
    },
  },
  methods: {
    caseHeight: function(){
      let height 
      if (this.menuCaseClass == 'MenuMobile--case'){
        // height = (95 / 6) * this.index + 'vh'
         height = (95 / 6) * this.index + '%'

      }else {
        // height = (100 / 6) * this.index + 'vh'
        height = (100 / 6) * this.index + '%'
      }
      // console.log(height)
      return height
    },
    mobileHeaderHeight: function(){
      if (this.isMobile){
        return 'var(--menu-header-height)'
      }else{
        return '0vw'
      }

    },

    testclick: function(){
      this.$emit('testclick', this.index)
    },

    mouseEnter: function(){
      this.$emit('mouse-enter', this.index)
    },

    mouseLeave: function() {
      this.$emit('mouse-leave', this.index)
    }

  },
  
  created(){
    // console.log('case created')
  }
}
</script>

<style lang="scss">

.fadePortrait-enter-active, .fadePortrait-leave-active{
  transition: transform 0.35s;
}

.fadePortrait-enter, .fadePortrait-leave-active{
  transform: translate(-100%);
}

.fadeLandscape-enter-active, .fadeLandscape-leave-active{
  transition: opacity 1s;
}

.fadeLandscape-enter, .fadeLandscape-leave-active{
  opacity: 0;
}






</style>
