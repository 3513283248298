<template>

	<div class="Scrollbar"
	:style="{top: positionTop + 'px', height: whatScrollbarHeight + 'px', right: whatHorizontalPosition}"
	:class="[scrollbarDisplay ? '':'isHiding', parent]">

		<div class="Scrollbar--thumb"
		:class="context"
		:style="{height: whatThumbHeight + '%', top: whatThumbPosition + 'px' }"
		></div>

	</div>
	
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
	name:'Scrollbar',
	mixins: [clientConfigs],
	props: ['context', 'scrollSequence', 'parent', 'activeFilter'],
	data: function(){
		return {
			elementParendNode: '',

			windowHeight: '',

			positionTop: '',
			positionBottom: '',
			positionHeight: '',

			scrollTop: '',

			totalHeight: '',

			isActive: true,


			scrollBarHeight: '',

			scrollDetect: false,
			displayCount: 0,

			windowListener: false,
			parentListener: false
		}
	},

	computed: {
    // whatConfig: function(){
    //   if ( this.ClientFormat == 'portrait' || this.$route.name == "decouvertes"){
    //     return 'portrait'

    //   }else if ( this.ClientFormat == 'landscape' && this.$route.name == "article" ) {
    //     return 'landscape-article'

    //   } else  if ( this.ClientFormat == 'landscape' && !(this.$route.name == "article") ) {
    //     return 'landscape-page'


    //     // ajouter else if decouvertes 

    //     // ajouter else if shop



    //     // ajouter mobile 


    //   }else {
				// return ''

    //   }
    // },
    whatConfig: function(){
    //   if ( this.ClientFormat == 'portrait' || this.$route.name == "decouvertes"){
    //     return 'portrait'
    //   }else if ( this.ClientFormat == 'landscape' && this.$route.name == "article" ) {
    //     return 'landscape-article'

    //   } else  if ( this.ClientFormat == 'landscape' && !(this.$route.name == "article") ) {
    //     return 'landscape-page'
    //   }else {
				// return ''
    //   }

      let whatConfigReturn 

      if (this.ClientFormat == 'portrait'){
				// whatConfigReturn = 'portrait'

				if (this.$route.name == "article"){
					whatConfigReturn = 'portrait-article'
				}else {
					whatConfigReturn = 'portrait-page'
				}


      }else if (this.ClientFormat == 'landscape'){

				if (this.$route.name == "article"){
					whatConfigReturn = 'landscape-article'

				}else if (this.$route.name == "decouvertes"){
					whatConfigReturn = 'landscape-page' // landscape à remettre
					// 
					// whatConfigReturn = 'portrait'

				}else if (this.$route.name == "boutique" || this.$route.name == "product"){
					whatConfigReturn = 'boutique'
				}else {
					whatConfigReturn = 'landscape-page'
				}

			}else {
				whatConfigReturn = ''
			}

      return whatConfigReturn
    },


		whatScrollbarHeight: function(){

			let scrollbarHeight 

			if ( this.whatConfig == "portrait-article" ){
				// scrollbarHeight = window.innerHeight - this.getMaxTopScrollbar()
				scrollbarHeight = this.windowHeight - this.getMaxTopScrollbar()
			}
			if ( this.whatConfig == "landscape-article"){
				// scrollbarHeight = window.innerHeight - this.getMaxTopScrollbar()
				scrollbarHeight = this.windowHeight - this.getMaxTopScrollbar()
			}

			if ( this.whatConfig == "landscape-page"){
				// scrollbarHeight = window.innerHeight;
				scrollbarHeight = this.windowHeight;
			}	

			if ( this.whatConfig == "boutique"){
				scrollbarHeight = this.windowHeight - this.getMaxTopScrollbar()
			}

			if (this.whatConfig == 'portrait-page'){
				scrollbarHeight = this.windowHeight - this.getMaxTopScrollbar()
			}
			//
			// console.log('whatScrollbarHeight', scrollbarHeight)
			return scrollbarHeight
		},
		whatThumbPosition: function(){

			// console.log('whatThumbPosition')

			let scrollMax = this.totalHeight - this.windowHeight
			let thumbHeight = this.whatThumbHeight * this.windowHeight / 100
			let thumbPositionMax = this.scrollBarHeight - thumbHeight

			let pourcentTopPosition = this.scrollTop / scrollMax * 100
			let thumbTopPosition = (pourcentTopPosition * thumbPositionMax) / 100

			return thumbTopPosition
		},
		whatThumbHeight: function(){
			return (this.whatScrollbarHeight / this.totalHeight) * 100
		},
		whatHorizontalPosition: function(){
			if (this.context == 'MainViewer-landscape'){
				let rightPosition = 'var(--article-thumbs-width)';
				return rightPosition
			}else {
				// return 0
				return 0
			}
		},
		scrollbarDisplay: function(){

			if ((this.windowHeight >= this.totalHeight) || this.scrollDetect == false || this.displayCount > 1){
				return false
			}else {
				if (this.scrollSequence == "sequence1" || this.scrollSequence == "sequence3"){
					return false
				}else {
					return true
				}
			}
		}
	},
	watch: {
		whatConfig: {
		immediate: false,
			handler(val) {
				this.removeListener(val)
				this.setListener(val)
			},
    },
   //  activeFilter:function(oldValue, newVal){
			// console.log('activeFilter', oldValue, newVal)
   //  }
	},

	methods: {
		scrollPageListener: function(){
			this.scrollTop = window.scrollY
			// console.log('scrollPageListener')
		},
		scrollElementListener: function(){
			this.scrollTop = this.$el.parentNode.scrollTop
			// console.log('scrollElementListener')
		},
		setDimension: function(){
			this.positionTop = this.getMaxTopScrollbar()

			this.positionBottom = this.$el.parentNode.getBoundingClientRect().bottom
			this.positionHeight = this.$el.parentNode.getBoundingClientRect().height

			this.scrollTopPortrait = this.$el.scrollTop
			this.scrollTopLandscape = window.scrollY
			// this.totalHeight = this.$el.parentNode.scrollHeight
			this.totalHeight = this.getTotalHeight()
			this.scrollBarHeight = this.$el.getBoundingClientRect().height
		},

		getTotalHeight: function(){ 
			if(this.$route.name == 'product' ){
				return this.$el.parentNode.scrollHeight - Number(window.getComputedStyle(document.documentElement).getPropertyValue('--shop-menu-header-height').split('px')[0]);
			}else {
				return this.$el.parentNode.scrollHeight
			}
		},

		getMaxTopScrollbar: function(){
			let scrollbarTop 

			if ( this.whatConfig == "portrait-article"){
				// scrollbarTop = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');
				scrollbarTop = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height').split('px')[0]);
			}

			if ( this.whatConfig == "landscape-article"){
				// scrollbarTop = window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article');
				let margeArticle = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--marge-article').split('vw')[0])
				scrollbarTop = ( margeArticle * window.innerWidth / 100)
			}

			if ( this.whatConfig == "landscape-page"){
				scrollbarTop = 0
			}	

			if (this.$route.name == "decouvertes"){
				if (this.ClientFormat == 'portrait'){
					scrollbarTop = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height').split('px')[0]);
				}
				if (this.ClientFormat == 'landscape'){
					scrollbarTop = 0
				}
			}

			if (this.whatConfig == "boutique"){
				scrollbarTop = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--shop-menu-header-height').split('px')[0]);
			}

			if (this.whatConfig == 'portrait-page'){
				scrollbarTop = Number(window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height').split('px')[0]);
			}

			return scrollbarTop
		},
		whatWindowHeight: function(){
			this.windowHeight = window.innerHeight
		},
		scrollListener: function(){
			this.scrollDetect = true
			this.displayCount = 0
		},
		setTimer: function(){
			this.timerInterval = setInterval(this.addTime , 1000);
		},
		removeTimer: function(){
			clearInterval(this.timerInterval)
		},
		addTime: function(){
			this.displayCount = this.displayCount + 1
		},
		onResize(){
			this.whatWindowHeight()
			this.setDimension()
			// console.log('scrollbar onResize')
		},
		setListener(whatConfig){

			if ( (whatConfig == "portrait-article" || whatConfig == "landscape-article") && !(this.parentListener)){
				this.elementParendNode.addEventListener('scroll', this.scrollElementListener)
				this.elementParendNode.addEventListener('scroll', this.scrollListener)

				this.parentListener = true
			}

			if ( whatConfig == "landscape-page" || whatConfig == "boutique" && !(this.windowListener) || this.whatConfig == 'portrait-page'){
				window.addEventListener('scroll', this.scrollPageListener)
				window.addEventListener('scroll', this.scrollListener)

				this.windowListener = true
			}
		},
		removeListener(){
			this.elementParendNode.removeEventListener('scroll', this.scrollElementListener)
			this.elementParendNode.removeEventListener('scroll', this.scrollListener)
			
			window.removeEventListener('scroll', this.scrollPageListener)
			window.removeEventListener('scroll', this.scrollListener)

			this.parentListener = false
			this.windowListener = false
		}
	},
	
	mounted(){
		this.elementParendNode = this.$el.parentNode
		this.setTimer()


		this.whatWindowHeight()
		this.setDimension() 	

		setTimeout(() => { 
			this.whatWindowHeight()
			this.setDimension() 
		}, 150);

		setTimeout(() => { 
			this.whatWindowHeight()
			this.setDimension() 
		}, 500);

		this.observer = this.$tools.setResizeObserverWithReturn(this.elementParendNode, () => {
			// console.log('scrollbar observer')
			this.whatWindowHeight()
			this.setDimension() 	
		})

		this.setListener(this.whatConfig)
		// console.log('scrollbar mounted', this.context, this.parent)
	},

	beforeDestroy(){
		this.removeTimer()
		this.removeListener()

		this.$tools.toggleResizeObserver(this.observer, this.elementParendNode, false)
		// console.log('scrollbar destroy', this.context, this.parent)
	}
}
	
</script>


<style lang="scss">
@import '@/css/variables';

.Scrollbar{
	pointer-events: none;
	position: fixed;
	width: 5px;

	right: var(--article-thumbs-width);

	transition: opacity 0.2s;
	z-index: 500;

	background-color: red;

	&.homepage{ background-color: transparent; }
	&.art{ background-color: var(--first-color); }
	&.amateur{ background-color: var(--second-color); }
	&.decouvertes{ background-color: var(--third-color); }
	&.recherche{ background-color: var(--fourth-color);  }
	&.article{ background-color: var(--article-color); }
	&.boutique-scrollbar{ background-color: var(--shop-color); }

	&--thumb{

		height: 100px;
		width: 100%;

		background-color: black;

		// background-color: rgba(200,0,130, 1);

		position: absolute;
		
		top: 0px;
		z-index: 500;

		&.MainViewer-landscape{
			background-color: white;
		}

	}
}

.Scrollbar.isHiding{
	opacity: 0;
	transition: opacity 0.2s;
}
	

</style>