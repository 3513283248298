<template>

  <transition :name="whatTransition" appear mode="out-in">

	<div class="Menu" @mousemove.prevent="cursorOnMenu" >

      <!-- <div class="Menu--articleSecurity"></div> -->

      <div v-if="cursorOnMenuValue" class="Menu--stop-mousemove" :class="this.pageIsArticle ? 'article': 'page'"></div>
      <div v-if="cursorSecurity" class="Menu--security" :style="securityPosition"></div>

      
      <!-- v-for="(element, index) in $site.menu" -->
      <component 
      :is="whatCase(index)"
      v-for="(element, index) in contentMenu"
      :key="index"
      :index="index" :whatzIndex="whatzIndex[index]" :uid="contentMenu[index].uid" :color="contentMenu[index].color" 
      @mouse-enter="mouseEnter"
      @mouse-leave="mouseLeave"
      @testclick="testclick"
      :caseDisplay="caseDisplay[index]">

        <template v-if="index == 0" v-slot:title>

            <!-- <div class="Menu--case--left-info">Profane</div> -->
            <router-link 
            class="Menu--case--left-info"
            v-bind:to="'/' + $route.params.language + contentMenu[index].uid"
            >Profane
          </router-link>

        </template>

        <template v-if="index == 0" v-slot:info>

            <div class="Menu--case--right-info">
              <div class="Menu--case--right-info--top">
                <div class="Menu--case--right-info--top--langage">
                  
                  <em 
                  class="Menu--case--right-info--top--langage--fr" 
                  :class="$route.params.language == 'fr' ? 'active':''"
                  @click="setLang('fr')">
                  fr</em> / 
                  <em 
                  class="Menu--case--right-info--top--langage--en" 
                  :class="$route.params.language == 'en' ? 'active':''"
                  @click="setLang('en')">
                  en</em>

                </div>
                <div class="Menu--case--right-info--top--newsletter">
                  <a :href="$site.links.newsletter" target="_blank">Newsletter</a>
                </div>       
              </div>
              <div class="Menu--case--right-info--bottom">
                <div class="Menu--case--right-info--bottom--links">
                  <a :href="$site.links.ig" target="_blank">Instagram</a><br>
                  <a :href="$site.links.fb" target="_blank">Facebook</a><br>
                  <a :href="$site.links.yt" target="_blank">Youtube</a>
                </div>
              </div>
            </div>

        </template>

        <template v-else> 

          <MenuCaseContent
          :index="index"
          :color="index == 5? 'white': ''"
          :content="contentMenu[index].name"
          ></MenuCaseContent>

        </template>

      </component>

	</div>

  </transition>

</template>

<script>
import MenuCase from '@/components/menu/MenuCase.vue'
import MenuCaseTitle from '@/components/menu/MenuCaseTitle.vue'

import MenuCaseContent from '@/components/menu/MenuCaseContent.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

import debounce from 'lodash/debounce';
import CSSvariables from '@/css/variables.scss'


export default {
  name: 'Menu',
  mixins: [clientConfigs],
  props: ['contentMenu'],
  components: {
    MenuCase,
    MenuCaseTitle,

    MenuCaseContent
  }, 
  data: function(){
    return {
      tabMenuIsHover: [false,false,false,false,false,false],
      cursorPositionX: '',
      cursorPositionY: '',
      MenuPosition: {
        start : '',
        end: ''
      },
      cursorOnMenuValue: '',
      cursorSecurity: false,
      isClicked: '',
      pageIsArticle: '',

      articleInfoAreaX: '',
      articleInfoAreaY: '',

      caseDisplay: [false, false, false, false, false, false]
    }
  },
  directives: {
    letterTitle:{
      inserted: function(el){
        setTimeout(function(){ 
          el
        // console.log(el, el.getBoundingClientRect().width)
        // console.log(el.nextElementSibling, el.nextElementSibling.getBoundingClientRect().width)
        // console.log(el.parentNode, el.parentNode.getBoundingClientRect().width)

        // window.addEventListener('resize', () => console.log(el))

        }, 100);
      }
    }
  },
  computed: {
    whatzIndex: function(){
        if (this.cursorOnMenuValue){
          return this.tabMenuIsHover.map(function(item) {
              return item;
          });
        }else {
          return 1
        }
    },
    securityPosition: function(){
      return {top: (100 / 6) * this.isClicked + 'vh'}
    }

  },

  watch: {

    cursorOnMenuValue: function(val){
      // console.log(val)
      if (val == false){
        this.cursorSecurity = false
      }
    },
    // '$route': function(val){
    //   this.pageIsArticle = val.name == 'article' ? true : false
    //   this.setMenuPosition()
    // },

    '$route': {
      deep: true,
      immediate: true,
      handler(val){
        this.pageIsArticle = val.name == 'article' ? true : false
        this.setMenuPosition()
      }
    },
    menuDisplay: function(val){
      console.log('menuDisplay', val)
      // if (val){
        this.setCaseDisplay()
      // }
    }
  },
  methods: {

    setLang: function(lang){

      // if (this.$route.params.language == 'fr'){
      if (lang == 'en' && this.$route.params.language == 'fr'){
        // document.location = document.location.href.replace('/fr/', '/en/')

        if (this.$route.name == "home"){
          document.location = document.location.href.replace('/fr', '/en')
        }else {
          document.location = document.location.href.replace('/fr/', '/en/')
        }

      }

      if (lang == 'fr' && this.$route.params.language == 'en'){
        // document.location = document.location.href.replace('/en/', '/fr/')

        if (this.$route.name == "home"){
          document.location = document.location.href.replace('/en', '/fr')
        }else{
          document.location = document.location.href.replace('/en/', '/fr/')
        }

      }

      // console.log('setLang', this.$route)
      // this.$api.apiLang = 'en'
      // this.$router.push({name: this.$route.name, params: {language: "en"}})
    },

    setCaseDisplay: function(){
      console.log('MENU NORMAL SETCASEDISPLAY')
    },
    whatCase: function(index){
      if (index == 0 ){
        return 'MenuCaseTitle'
      }else {
        return 'MenuCase'
      }


    },
    testclick: function(index){
      this.isClicked = index
      this.cursorSecurity = true
      this.tabMenuIsHover.forEach((element, index) => {
        this.$set(this.tabMenuIsHover, index, false)
      })
    },
    mouseEnter: function(index){
      this.$set(this.tabMenuIsHover, index, true)
    },
    mouseLeave: function(index){
      this.$set(this.tabMenuIsHover, index, false)
    },
    cursorOnMenu: function(e){ 



      this.cursorPositionX = e.clientX
      this.cursorPositionY = e.clientY
      
      let conditionPage = this.cursorPositionX < this.MenuPosition.start || this.cursorPositionX > this.MenuPosition.end

      // let conditionArticle = this.cursorPositionX < this.MenuPosition.start || this.cursorPositionY < this.MenuPosition.top
      let conditionArticle = (this.cursorPositionX < this.MenuPosition.start) || (this.cursorPositionY < this.MenuPosition.top) || (this.cursorPositionX > this.articleInfoAreaX && this.cursorPositionY < this.articleInfoAreaY)


      let condition = this.pageIsArticle ? conditionArticle : conditionPage

      let result = condition ? true : false
      if ( this.cursorOnMenuValue != result ){
        this.cursorOnMenuValue = condition ? true : false 
      }
    },
    setMenuPosition: function(){ // resize
      let windowWith = window.innerWidth

      this.articleInfoAreaX = 9/10 * windowWith
      this.articleInfoAreaY = 1/6 * window.innerHeight

      if (this.pageIsArticle){
        let margeArticle = Number(CSSvariables.margearticle.split('vw')[0])
        this.MenuPosition.start = windowWith * (margeArticle) / 100
        this.MenuPosition.top = windowWith * (margeArticle) / 100

      }else {
        let pagewidth = Number(CSSvariables.pagewidth.split('vw')[0])
        let margePage = (100 - pagewidth) / 2
        this.MenuPosition.start = windowWith * (margePage) / 100
        this.MenuPosition.end = windowWith * ( 100 - (margePage)) / 100
      }
    },
    onResize: function (){
      this.setMenuPosition()
    }
  },
  mounted(){
    this.setMenuPosition()
    this.onResizeDebounced = debounce(this.onResize, 100)
    window.addEventListener('resize', this.onResizeDebounced)

    // window.addEventListener('resize', this.onResize)

    this.caseDisplay.forEach((element, index) => {
      setTimeout(() => {  this.$set(this.caseDisplay, index, !element)  }, 200 * index);
    })

  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResizeDebounced)
    // window.removeEventListener('resize', this.onResize)

    // console.log('MENU DESTROY')
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

@import '@/css/transition';





.Menu{

  position: absolute;

  width: 100vw;
  height: 100vh;

  overflow: hidden;


  // transform: translate(0,-80vh);

  &--stop-mousemove{

    position: fixed;
    z-index: 50;

    // background-color: rgba( 210, 0, 0, 0.2);

    &.page{

      height: 100vh;
      width: var(--page-width);
      left: calc(var(--marge-page) / 2); 

    }

    // &.article{

    //   left: var(--marge-article);
    //   top: var(--marge-article);

    //   width: calc(100vw - var(--marge-article));
    //   height: calc(100vh - var(--marge-article));
    // }

    &.article{

      left: var(--marge-article);
      top: var(--marge-article);

      width: calc(100vw - var(--marge-article) - 10vw);
      height: calc(100vh - var(--marge-article));
    }
  }

  &--security{
    position: fixed;
    width: 100vw;
    z-index: 50;
    height: calc(100vh / 6);

    // background-color: rgba( 210, 0, 0, 1);
  }

  &--case{
    display: block;

    width: 100vw;
    height: calc(16.7vh); 

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: fixed;

    &--left{      
      line-height: 0.7;
      margin-left: 0.5vw;

      position: absolute;
      float: left;

      height: 100%;

      top: 17%;


      font-size: min(max(1px, 18vh), 18.8vw);
      // font-size: clamp( 1px, 18vh, 18.8vw);
      // @supports ( font-size: clamp( 1px, 18vh, 18.8vw)) {
      //   font-size: clamp( 1px, 18vh, 18.8vw);
      // }

      & .lighten{
        color: black;
      }
    }

    &--right{       
      line-height: 0.7;
      right: 1vw;

      position: absolute;
      float: right;

      height: 100%;
      align-items: center;
      
      top: 29%;

      // font-size: clamp( 1px, 15vh, 18.8vw); 
      font-size: min(max(1px, 15vh), 18.8vw);
      // font-size: clamp( 1px, 15vh, 18.8vw);
      // @supports ( font-size: clamp( 1px, 15vh, 18.8vw)) {
      //   font-size: clamp( 1px, 15vh, 18.8vw);
      // }
    }
  }


.Menu--case--left-info{
      opacity: 1;
      align-self: flex-end;
      
      margin-left: 0.5vw;

      line-height: 0.8;
      width: 100%;

      font-size: min(max(1px, 18vh), 18.8vw);
      // font-size: clamp( 1px, 18vh, 18.8vw);
      // @supports ( font-size: clamp( 1px, 18vh, 18.8vw)) {
      //   font-size: clamp( 1px, 18vh, 18.8vw);
      // }
}

.Menu--case--right-info{  

  text-align: right;
  font-size: 2vh;
  line-height: 1.2;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  height: calc(100vh / 6);
  z-index: 10;

  &--top{
    position: absolute;
    // top: 0.7vh;
    // right: 0.7vh;

    top: 1vh;
    right: 1.2vh;

    &--langage{}
    &--newsletter{}
  }

  &--bottom{
    position: absolute;
    // bottom: 0.7vh;
    // right: 0.7vh;

    right: 1.2vh;
    bottom: 1vh;
  &--links{}

  }
}

}

.Menu--case--right-info--top--langage--fr{
  cursor: pointer;

  &.active{
    text-decoration: underline;
  }
}
.Menu--case--right-info--top--langage--en{
  cursor: pointer;
  &.active{
    text-decoration: underline;
  }
}






</style>