import Vue from 'vue'
import App from './App.vue'
import KirbyApi from '@/plugins/kirby-api'
import Tools from '@/plugins/tools'
import Datastore from '@/plugins/Datastore'
import RedirectShopStore from '@/plugins/RedirectShopStore'
import Client from '@/plugins/Client'
import Router from './router'

Vue.config.productionTip = false

let init_site = async () => {

	// 0. gérer la version fr et version anglais
	// capter le fr ou en dans l'url
	let path = document.location.pathname
	let splitPath = path.split('/')
	let language = splitPath[1]
	language
	// console.log('document.location.pathname', document.location.pathname, ', document.location.href', document.location.href)
	// console.log('path ', path, ', splitPath ', splitPath, 'language ', language)

	// 1. Définir l'url de l'API .env
	let apiRoot = process.env.VUE_APP_KIRBY_URL // 'http://localhost:8888/'
	let apiLang = language.length > 0 ? language : 'fr' // 'fr'
	// let apiURL = apiRoot + apiLang  //'http://localhost:8888/fr'
	let apiURL = apiRoot  //'http://localhost:8888/fr'

	// console.log(apiURL)

	// console.log("apiRoot", apiRoot)
	// console.log("apiLang", language)
	// console.log("apiURL", apiURL)

	// 2. requette API pour ENV
	let api = KirbyApi.init(apiURL, apiLang)
	let env_site = await api.getSite()


	// 3. Définir le tab à transmettre au router (avec la response 2. )
	
	// 3. Initialisation des singletons ( à voir si $site autonome ou dans le store)
	let datastore = Datastore.init()
	let client = Client.init(apiLang)
	let redirectShopStore = RedirectShopStore.init()


	// TOOLS
	let tools = Tools.init()
	// tools.setSnipcartConfig()

	// 4. Définir le routeur manuellement
	
	let router = await Router.init(datastore, api, client, redirectShopStore, env_site.meta)
	datastore.setStaticMetaContent(apiLang, env_site.meta)

	// Extra. Définir les variables globales
	Vue.prototype.$site = env_site 	 // variable global ou singleton datastore ????? (language, menu)
	Vue.prototype.$apiUrl = apiURL

	Vue.prototype.$api = api
	Vue.prototype.$store = datastore
	Vue.prototype.$redirectShopStore = redirectShopStore
	Vue.prototype.$client = client
	Vue.prototype.$tools = tools

	// new Vue({
	// 	router,
	// 	render: h => h(App),
	// 	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
	// }).$mount('#app')

	new Vue({
		router,
		render: h => h(App),
		async mounted() {
			setInterval(function(){ document.dispatchEvent(new Event('x-app-rendered')) }, 800);
		},
	}).$mount('#app')

}

init_site()





