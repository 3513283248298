<template>
  <div class="MenuMobile">

    <div v-if="!menuDisplay" class="MenuMobile--security" @click="testclick"></div>

    <transition name="slide">

      <template v-if="menuDisplay">

      <div class="MenuMobile--case-info" :style=" {'z-index': whatzIndex ? '10' : '1'} "
      >
        <div class="MenuMobile--case-info--links">
          <div class="MenuMobile--case-info--links--newsletter"> <a :href="$site.links.newsletter" target="_blank">Newsletter</a></div>
          <template v-if="whatComputConfig != '-mobile'">
          <div class="MenuMobile--case-info--links--fb"><a :href="$site.links.fb" target="_blank">Facebook</a></div>
          <div class="MenuMobile--case-info--links--yt"><a :href="$site.links.yt" target="_blank">Youtube</a></div>
          <div class="MenuMobile--case-info--links--ig"><a :href="$site.links.ig" target="_blank">Instagram</a></div>
          </template>
          <div class="MenuMobile--case-info--links--langage">
            <em 
            class="MenuMobile--case-info--links--langage--fr" 
            :class="$route.params.language == 'fr' ? 'active':''"
            @click="setLang('fr')">
            fr</em> / 
            <em 
            class="MenuMobile--case-info--links--langage--en" 
            :class="$route.params.language == 'en' ? 'active':''"
            @click="setLang('en')">
            en</em>
        </div>

        </div>
        <div class="MenuMobile--case-info--button" @click="setMenuOff">
          <img class="MenuMobile--case-info--button--img" src="@/assets/icone/exit.svg">
        </div>

      </div>

      </template>

    </transition>

    <transition name="slide">

      <template v-if="!menuDisplay">
        
        <div class="MenuMobile--header-button">
          
          <div class="MenuMobile--header-button--left" >Profane</div>

          <div class="MenuMobile--header-button--right">
            <div class="MenuMobile--header-button--right--color"
            v-for="i in 4" 
            :key="i"
            :style="{'background-color': contentMenu[i].color }"
            >
            </div>
          </div>

        </div>

      </template>

      </transition>

      <MenuCase 
      v-for="(element, index) in contentMenu"
      :key="index"
      :index="index" :whatzIndex="whatzIndex" :uid="contentMenu[index].uid" :color="contentMenu[index].color" 
      :menuCaseClass="'MenuMobile--case'"
      :isMobile="true"
      :whatCaseHeight="whatCaseHeight()"
      @testclick="testclick"

      :caseDisplay="caseDisplay[index]">

        <template v-if="index == 0">
        <div class="MenuMobile--case--element title">Profane</div>
        </template>
        <template v-else>
          <div class="MenuMobile--case--element" :style="{'color': index == 5? 'white': ''}">{{contentMenu[index].name}}</div>
        </template>

      </MenuCase>

  </div>
</template>

<script>
import MenuCase from '@/components/menu/MenuCase.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'MenuMobile',
  mixins: [clientConfigs],
  props: ['contentMenu'],
  components: {
    MenuCase
  },
  data: function(){
    return {
      isClicked: '',
      menuDisplay: false,
      caseDisplay: [false, false, false, false, false, false],
      newValueDisplay : false
    }
  },
  watch: {
    menuDisplay: function(){
        this.setCaseDisplay()
    }
  },
  computed: {

    whatzIndex: function(){
      let zindex
      if (this.menuDisplay){
        // zindex = 5
        zindex = 5
      }else {
        zindex = 0
      }
      return zindex
    }
  },

  methods: {

    setLang: function(lang){

      if (lang == 'en' && this.$route.params.language == 'fr'){
        // document.location = document.location.href.replace('/fr/', '/en/')

        if (this.$route.name == "home"){
          document.location = document.location.href.replace('/fr', '/en')
        }else {
          document.location = document.location.href.replace('/fr/', '/en/')
        }

      }

      if (lang == 'fr' && this.$route.params.language == 'en'){
        // document.location = document.location.href.replace('/en/', '/fr/')

        if (this.$route.name == "home"){
          document.location = document.location.href.replace('/en', '/fr')
        }else{
          document.location = document.location.href.replace('/en/', '/fr/')
        }

      }
    },

    whatCaseHeight: function(){

      let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');

      let pixelTotalHeight = window.innerHeight 
      let pixelMargeHeight = margeArticleRaw.split('px')[0]
      let pixelContentHeight = window.innerHeight - pixelMargeHeight + 1

      let pourentCaseHeight = pixelContentHeight * 100 / pixelTotalHeight / 6

      return pourentCaseHeight + '%'

    },

    setCaseDisplay: function(){

      this.caseDisplay.forEach((element, index)=> {

        if (index == 0) { this.newValueDisplay = !element }
          
        // 80
        setTimeout(() => {  this.$set(this.caseDisplay, index, this.newValueDisplay)  }, 60 * index);

        /* securité : toutes les cases ont la même valeur d'affichage */
        setTimeout(() => {  this.$set(this.caseDisplay, index, this.newValueDisplay)  }, 60 * 5);
      })

    },
    setMenuOff: function(){
      this.menuDisplay = false
    },
    testclick: function(){
      this.menuDisplay = !this.menuDisplay
    },
    onResize: function(){
      this.$forceUpdate();
    }
  },
  created(){
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }


}
</script>

<style lang="scss">
@import '@/css/variables';


.slide-enter-active, .slide-leave-active{
  transition: transform 0.35s;
}

.slide-enter{
  // opacity: 0;
  transform: translate(-100%);
}

.slide-leave-active{
  transform: translate(100%);
}



.MenuMobile{
  position: absolute;
  width: 100vw;
  // height: 100vh;
  overflow: hidden;

  // height: 100%;
  height: calc(100% - var(--menu-header-height));

    &--case{

    display: block;

    width: 100vw;
    // height: calc( ((100vh - var(--menu-header-height)) / 6) + 1px); 
    align-items: center;

    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc( 100% / 6 ); 

    &--element{

      position: relative;
      align-self: center;

      font-size: min(max(3rem, 10vh), 18vw);
      // font-size: clamp( 3rem, 10vh, 18vw);
      // @supports (font-size: clamp( 3rem, 10vh, 18vw)) {
      //   font-size: clamp( 3rem, 10vh, 18vw);
      // }

      &.title{

        font-size: min(max(4rem, 16.5vh), 34.5vw);
        // font-size: clamp( 4rem, 16.5vh, 34.5vw);
        // @supports (font-size: clamp( 4rem, 16.5vh, 34.5vw)) {
        //   font-size: clamp( 4rem, 16.5vh, 34.5vw);
        // }
        
        top: 5%;
      }
    }
  }
}

.MenuMobile--case-info{
  position: fixed;
  width: 100vw;
  height: var(--menu-header-height);
  top: 0;

  background-color: rgba(240, 240, 240, 1);

  display: block;
  position: fixed;
  // z-index: 1;
  
  display: flex;
  justify-content: space-between;

  font-size: calc( 0.55 * var(--menu-header-font));
  // font-size: min(max(0.8rem, 2.3vh), 5vw);
  // font-size: clamp( 0.8rem, 2.3vh, 4.5vw);
  // @supports ( font-size: clamp( 0.8rem, 2.3vh, 4.5vw)) {
  //   font-size: clamp( 0.8rem, 2.3vh, 4.5vw);
  // }
  
  z-index: 10;

  &--links{
    display: flex;
    width: 85%;

    > div {
      margin-left: 2vh;
      align-self: center;
      cursor: pointer;
    }
  }

  &--button{
    width: var(--menu-header-height);
    background-color: rgb(200,200,200);
    cursor: pointer;

    // background-image: url('../../assets/icone/exit.svg');
    // background-repeat: no-repeat;

    z-index: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    &--img{
      max-height: 80%;
      max-width: 80%;

    }
  }

  &--langage{
    display: flex;
    width: 15%;
    background-color: green;

    > div {
      margin-left: 2vh;
      align-self: center;
    }
  }
}

.MenuMobile--header-button{
  display: block;
  position: fixed;
  width: 100vw;
  height: var(--menu-header-height);  
  top: 0;
  background-color: rgba(240, 240, 240, 1);
  

  cursor: pointer;

  display: flex;
  z-index: 10;

  &--left{
    width: 30%;
    font-size: var(--menu-header-font);
    margin: 0.2em;
  }

  &--right{
    width: 70%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    &--color{
      width: var(--menu-header-height);
      background-color: red;
    }
  }
}

.MenuMobile--security{
  position: fixed;
  width: 100vw;
  height: var(--menu-header-height);
  // background-color: rgba( 210, 0, 0, 0);
  cursor: pointer;
  z-index: 20;
}


.MenuMobile--case-info--links--langage--fr{
  cursor: pointer;
  &.active{
    text-decoration: underline;
  }
}
.MenuMobile--case-info--links--langage--en{
  cursor: pointer;
  &.active{
    text-decoration: underline;
  }
}














</style>