<template>

  <div class="RedirectShopApp" :class="[titleisHiding ? 'isHiding':'']">

    <div class="RedirectShopApp--case-container"
    :style="{'width': whatCaseContainerWidth}">

      <div 
      class="RedirectShopApp--case-container--slide"
      :style="{transform: 'translate(' + whatTranslate + 'vw)'}">

        <RedirectShopCase
        v-for="(product, index) in Dynamic"
        :key="index"
        :content="product"
        :index="index"
        :caseWidth="whatButtonWidth"
        ></RedirectShopCase>

      </div>

    </div>

    <div 
    class="RedirectShopApp--button-right" 
    :style="{'width': whatButtonWidth}"
    @click="setIndex(1)">
      <img class="RedirectShopApp--button-right--img" src="@/assets/icone/arrow_right.svg">
    </div>

  </div>


</template>

<script>
import RedirectShopCase from '@/components/boutique/headershop/RedirectShopCase.vue'

import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'RedirectShopApp',
  mixins: [clientConfigs],
  components: {
    RedirectShopCase
  },
  props: [''],
  data: function(){
    return {
      state : this.$redirectShopStore.state,

      // index: this.$tools.getRandom(this.content.length - 1),
      index: 0,
      slideTranslate: 4,
      Dynamic: [],
      // caseDisplay: 8, // computed

      initNumber: '',

      limitMini: 0,
      // limitMax: // computed

      AddContentMaxCount: 0,
      maxAddContentMax: 2

    }
  },
  watch: {
    '$route.params.uid': {
      deep: true,
      immediate: true,
      handler(val){
        // console.log(val)
        this.setRouterIndex(val)
        this.$redirectShopStore.setTitleisHiding(false)
      }
    },   
  },
  computed:{

    content: function(){
      return this.$redirectShopStore.state.[this.$route.params.language].redirectShop
    },

    titleisHiding: function(){
      // console.log('RedirectShopApp', this.$redirectShopStore.state.data.titleisHiding)
      return this.state.data.titleisHiding
    },

    caseDisplay: function(){

      let caseDisplayContext

      caseDisplayContext = 8

      if ( this.ClientWidth == 'tabletWidth' ){ caseDisplayContext = 6}
      if ( this.ClientWidth == 'desktopSmall' ){ caseDisplayContext = 8}
      if ( this.ClientWidth == 'desktopMed' ){ caseDisplayContext = 11}
      if ( this.ClientWidth == 'desktopBig' ){ caseDisplayContext = 13}
      if ( this.ClientWidth == 'desktopBigger' ){ caseDisplayContext = 13}
      if ( this.ClientWidth == 'desktopBiggest' ){ caseDisplayContext = 13}
      if ( this.ClientWidth == 'desktopMax' ){ caseDisplayContext = 15}

      return caseDisplayContext
    },

    whatTranslate: function(){
      return -1 * this.index * (100 / (this.caseDisplay + 1))    /*** caseDisplay */
    },
    whatCaseContainerWidth: function(){
      return 'calc(100vw /' + (this.caseDisplay + 1) + '*' + this.caseDisplay + ')'
    },
    whatButtonWidth: function(){
      return 'calc(100vw / ' + (this.caseDisplay + 1) + ')'
    },

    limitMax: function(){
      return this.content.length - this.caseDisplay
    }

  },
  methods: {
    setRouterIndex: function(val){

    this.content.forEach((element, index) => {
      if (element.uid == val && !this.initNumber){
        this.index = index
      }
    })

    },
    setIndex: function(direction){
 
      let newIndex = this.index + direction

      if (newIndex > (this.Dynamic.length - this.caseDisplay - this.slideTranslate)){

        // console.log('MAX', this.AddContentMaxCount)

        if (this.AddContentMaxCount < this.maxAddContentMax ){
          this.addContentMax()
          setTimeout(() => {  this.index = this.index + this.slideTranslate }, 50);
          // this.index = this.index + this.slideTranslate
        }else {
           // limit maximal
           this.index = 0
        }

      }else if ( newIndex < (this.limitMini )){
        // console.log('MINI')
        this.index = this.content.length - this.caseDisplay

      }else {
        this.index = this.index + (direction * this.slideTranslate)

      }
    },

    addContentMax: function(){
      // console.log('addContentMax')

      this.content.forEach((element) => {
        this.Dynamic.push(element)
      })
      this.content.forEach((element) => {
        this.Dynamic.push(element)
      })

      this.AddContentMaxCount = this.AddContentMaxCount + 1
      // console.log(this.Dynamic)
    },

  },
  created(){
    this.content.forEach((element, index) => {
      if (element.uid == this.uid && !this.initNumber){
        this.initNumber = index
      }
    })
    this.addContentMax()


  },

  mounted(){
    setTimeout(() => { 
      if (this.initNumber){
        this.index = this.initNumber 
      }else {
        this.index = this.$tools.getRandom(this.content.length - 1)
      }
    }, 800);
  },
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.RedirectShopApp{

  position: fixed;
  width: 100%;
  top: var(--shop-menu-header-height);
  height: var(--shop-menu-redirect-height); // variable.css en pixel ?

  z-index: 199;
  transition: transform 0.3s;

  display: flex;

  &.isHiding{
    transform: translate(0, -100%);
  }

  &--button-right{
    height: 100%;
    // background-color: var(--shop-color);
    background-color: var(--shop-bg-color);
    cursor: pointer;
    // background-image: url(~@/assets/icone/fleche1_right.svg);
    // background-repeat: no-repeat;

    // background-size: 150px 75px;
    // background-repeat: no-repeat;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--img{
      max-height: 75%;
      max-width: 75%;
    }
  }

  &--case-container{
    overflow: hidden;

    display: flex;

    &--slide{
      display: flex;
      transition: transform 0.5s ease;
    }
  } 
}






</style>