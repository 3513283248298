import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

export default {
  init: (datastore, api, client, redirectShopStore, envMetaContent) => {
    datastore, api

    const routes = [
      {
        path: '/:language',
        name: 'home',
        component: Home
      },
      {
        path: '/:language/art-amateur',
        name: 'art',
        component: () => import( '../views/Articles.vue')
      },
      {
        path: '/:language/amateur-d-art',
        name: 'amateur',
        component: () => import( '../views/Articles.vue')
      },
      {
        path: '/:language/decouvertes',
        name: 'decouvertes',
        component: () => import( '../views/Decouvertes.vue')
      },
      {
        path: '/:language/recherche',
        name: 'recherche',
        component: () => import( '../views/Recherche.vue')
      },
      {
        path: '/:language/boutique',
        name: 'boutique',
        component: () => import( '../views/Boutique.vue')
      },
      {
        path: '/:language/boutique/:uid',
        name: 'product',
        component: () => import( '../views/Product.vue')
      },
      {
        path: '/:language/cgv',
        name: 'cgv',
        component: () => import( '../views/Cgv.vue')
      },
      {
        path: '/:language/:uid',
        name: 'article',
        components: {
          default: () => import( '../views/Article.vue'),
          mobile: () => import('../views/ArticleMobileTape.vue')
        }
      },
      {
        path: '/:language/:uid/#/cart',
      },

      {
        path: '*',
        redirect: '/fr/'
      }
    ]

    // return new VueRouter({
    //    mode: 'history',
    //    routes
    // })

    const router = new VueRouter({
       mode: 'history',
       routes
    })



    router.beforeEach((to, from, next) => {

      // console.log(to.name)

      if (to.name == 'cgv'){

        next(true)

      }else {

      let isLoaded = datastore.pageIsLoaded(to.params.language, to.name)
      
      // console.log(to, from)


      // decouvertes -> recherche (contributeurs) : keep-alive historique
      // if (from.name == 'decouvertes' && to.name == 'recherche' || from.name == 'recherche' && to.name == 'decouvertes'){
      if (from.name == 'decouvertes' && to.name == 'recherche' || to.name == 'decouvertes'){
        client.setKeepAlive(true)
      }else {
        client.setKeepAlive(false)
      }

      client.setViewTransition(to.name, from.name)


      setTimeout(() => { 

        if (!isLoaded || to.name == 'article' || to.name == 'product'){   // console.log('CALL API LOADING')
          
          const callApi = async (lang, page, envMetaContent) => { 

            let response = await api.getPageRouter(page, to.params.uid)
            datastore.setPage(lang, page, response)

            // document.documentElement.setAttribute('lang', lang)
            datastore.setDynamicMetaContent(lang, page, envMetaContent)

            if (to.name == 'product'){
              datastore.setRedirectShop(lang, response.redirect)
              redirectShopStore.setRedirectShop(lang, response.redirect)
            }

            // console.log('CALL API LOADED')
            next(true)
          }

          callApi(to.params.language, to.name, envMetaContent)

        }else { // console.log('CALL API ALLREADY LOADED')

          // document.documentElement.setAttribute('lang', to.params.language)
          datastore.setDynamicMetaContent(to.params.language, to.name, envMetaContent)

          next(true)
        }

      }, 10);

      }


      
    })

    return router
  }
}
