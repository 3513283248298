<template>
	<div class="Newsletter">

			<div id="mc_embed_signup Newsletter--form-container">
				<form action="https://revueprofane.us13.list-manage.com/subscribe/post?u=9e1c326eef604d191f53c2045&amp;id=b40cdd3017" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate form-container--form" target="_blank" novalidate>

					<div id="mc_embed_signup_scroll Newsletter--form-container--form--container">
							
						<div class="mc-field-group Newsletter--form-container--form--container--email">
							<!-- <label for="mce-EMAIL">Email Address</label> -->
							<input :class="whatHeight" type="email" value="" :placeholder="whatContentLangMail" name="EMAIL" class="required email" id="mce-EMAIL">
						</div>

						<div id="mce-responses" class="clear Newsletter--form-container--form--container--hide">
							<div class="response" id="mce-error-response" style="display:none"></div>
							<div class="response" id="mce-success-response" style="display:none"></div>
						</div>  

						<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
						<div class="clear Newsletter--form-container--form--container--hide2"  style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_9e1c326eef604d191f53c2045_b40cdd3017" tabindex="-1" value=""></div>

						<div class="clear Newsletter--form-container--form--container--button" :class="whatHeight">
							<input type="submit" :value="whatContentLangRegister" name="subscribe" id="mc-embedded-subscribe" class="button">
						</div>

					</div>

				</form>
			</div>


<!-- https://revueprofane.us13.list-manage.com/subscribe/post?u=9e1c326eef604d191f53c2045&id=b40cdd3017 -->


<!-- Begin Mailchimp Signup Form -->
<!-- <div id="mc_embed_signup">
<form action="https://revueprofane.us13.list-manage.com/subscribe/post?u=9e1c326eef604d191f53c2045&amp;id=b40cdd3017" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address </label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_9e1c326eef604d191f53c2045_b40cdd3017" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div> -->
<!--End mc_embed_signup-->
		
	</div>
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'Newsletter',
  mixins: [clientConfigs],
  props: ['context'],
	directives: {
		LazyLoading
  },
  computed: {
		whatContentLangMail: function(){

			if ( this.ClientWidth == 'mobileWidth'){
				return 'E-mail'

			}else {

				if (this.$route.params.language == 'fr'){
					return 'Adresse e-mail'
				}else {
					return 'E-mail adress'
				}

			}
		},  
		whatContentLangRegister: function(){

			if ( this.ClientWidth == 'mobileWidth'){
				return '✓'

			}else {

				if (this.$route.params.language == 'fr'){
					return 'S\'enregistrer'
				}else {
					return 'Register'
				}

			}

		}, 
		whatHeight: function(){
			if (this.context == 'homepage'){
				return 'homepage-newsletter'
			} else if (this.context == 'article'){
				return 'article-newsletter'
			}else if (this.context == 'shop-animate'){
				return 'shop-animate'
			}else if (this.context == 'homepage-large'){
				if(this.ClientWidth == "mobileWidth"){
					return 'homepage-newsletter'
				}else {
					return 'homepage-large-newsletter'
				}
				
			}else {
				return ''
			}
		}

  },
  created(){
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

#Newsletter--form-container{

	width: 100%;
	height: 100%;
}

#mc_embed_signup{
	width: 100%;
	height: 100%;
}

.Newsletter{
		width: 100%;
		height: 100%;
		position: relative;

	&--form-container{
		
		width: 100%;
		// width: 80vw;
		height: 100%;

		&--form{
			width: 100%;
			// width: 80vw;
			height: 100%;

		
			&--container{

				width: 100%;
				// width: 80vw;
				height: 100%;

				

				&--email{
					display: inline-block;
					width: 60%;
					// width: 40vw;
					height: 100%;



					& label{ height: 100%; }

					& input{
						height: 100%;
						border: 0;
						// border-bottom: 0.6vw solid;
						outline: 0;
						color: black;
						
						background: white;


						// padding: var(--esp-big);
						padding-left: var(--esp-big);
						// width: calc(100% - var(--esp-big));

						// width: calc(100% - var(--esp-big));
						width: calc(60% - var(--esp-big));

						// width: 100%;

						font-family: 'oli';

						// height: 4vw; // !!!!!! :style=""

						// font-size: var(--redirect-font-title);
						font-size: inherit;

						position: absolute;
						left : 0;
						top: 0;

						padding-bottom: 0;
						padding-top: 0;

						&.homepage-large-newsletter{
							height: calc(1.3 * var(--home-height-case-newsletter));
						}

						&.homepage-newsletter{
							height: var(--home-height-case-newsletter);
						}

						&.article-newsletter{
							height: var(--redirect-title-height);
						}

						&.shop-animate{
							height: var(--shop-menu-header-height);
							width: calc(60% - var(--esp-small));
							padding-left: var(--esp-small);
						}
					}
				}

				&--hide{
					position: absolute;
					display: inline-block;
				}

				&--hide2{
					position: absolute;
					display: none;
				}

				&--button{
					display: inline-block;
					width: 40%;
					background-color: grey;
					height: 100%;


					position: absolute;
					left: 60%;
					top: 0;
					text-align: center;
					padding-bottom: 0;
					padding-top: 0;

					&.homepage-large-newsletter{
						height: calc( 1.3 * var(--home-height-case-newsletter));
					}

					&.homepage-newsletter{
						height: var(--home-height-case-newsletter);
					}
					&.article-newsletter{
						height: var(--redirect-title-height);
					}
					&.shop-animate{
						height: var(--shop-menu-header-height);
						text-align: center;
						width: 40%;
					}

					& input{
						font-family: 'oli';
						// font-size: var(--redirect-font-title);
						font-size: inherit;
						border: 0;
						background: transparent;
						cursor: pointer;

						padding: 0;

						vertical-align: middle;
						height: 100%;
					}

				}
			}
		}
	}
}

</style>