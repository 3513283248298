<template>
	<div class="HomepageArticleMobile">

	<router-link 
	v-bind:to=" '/' + $route.params.language + '/' + content.uid "
	class="HomepageArticleMobile" 
	:style="{'background-color': whatColor}"
	>
		<div class="HomepageArticleMobile--text-container">
			<div class="HomepageArticleMobile--text-container--title" v-html="content.title"></div>
			<div class="HomepageArticleMobile--text-container--text" v-html="content.subtitle_cover"></div>
		</div>	

		<template v-if="content.hasVideo == 'true'">
			<div class="HomepageArticleMobile--img-container">
				<!-- <video class="HomepageArticleMobile--img-container--img" autoplay loop muted="" :poster="content.cover.video_cover[0].poster"> -->
				<video class="HomepageArticleMobile--img-container--img" autoplay loop muted playsinline :poster="content.cover.video_cover[0].poster">
					<source :src="content.cover.video_cover[0].url" type="video/mp4" />
				</video>
			</div>
		</template>

		<template v-else>
			<img class="HomepageArticleMobile--img-container" 
			v-lazyloading
			:alt="content.title" 
			:src="content.covers.portrait.thumb" 
			:data-srcset="content.covers.portrait.srcset" 
			sizes="90vw">
		</template>

	</router-link>
	</div>
</template>

<script>

export default {
  name: 'HomepageArticleMobile',
  props: ['content', 'index'],
  computed: {
		whatColor: function(){
			let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
			let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');
			return this.content.category === 'art' ? artColor : amateurColor; 
		},

  },
	mounted(){
		// console.log('HomepageArticleMobile')
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.HomepageArticleMobile{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	z-index: 2;

	&--text-container{
		width: 100%;
		margin: var(--esp-big);

		&--title{
			font-size: var(--home-font-case1-title);
			line-height: 0.9;
			word-break: break-word;

			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}
		}

		&--text{
			font-family: 'vd-ita';
			font-size: var(--home-font-case1-text);

			& p {

				& em {
					font-family: 'vd-reg';
				}
			}
		}
	}

	&--img-container{
		width: 100%;
		overflow: hidden;

		&--img{
			width: 100%;
			height: 100%;
			transform: scale(1.05);
		}
	}
}

</style>