<template>
  <router-link 
  class="RedirectShopCase"
  v-bind:to=" '/' + $route.params.language + '/boutique/' + content.uid"
  :style="{'width': caseWidth}"
  @mouseenter.native="mouseEnter"
  @mouseleave.native="mouseLeave">

  <div class="RedirectShopCase--bg"></div>

  <transition name="RedirectShopCaseToggleInvert" mode="out-in">

  <div class="RedirectShopCase--img-container" 
  v-show="!titleIsVisible">

    <img 
    class="RedirectShopCase--img-container--img" 
    v-lazyloading
    :src="content.cover.thumb"
    :data-srcset="content.cover.srcset" 
    sizes="12vw">

  </div>

  </transition>

  <transition name="RedirectShopCaseToggle" mode="out-in">

  <div class="RedirectShopCase--text-container"
  v-show="titleIsVisible" v-html="content.title">
    <!-- <p>{{content.title}}</p> -->
  </div>

  </transition>

  </router-link >
</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'RedirectShopCase',
  directives:{
    LazyLoading
  },
  props: ['index', 'content', 'caseWidth'],
  data: function(){
    return{
      titleIsVisible: false
    }
  },
  computed:{

  },
  methods: {
    mouseEnter: function(){
      this.titleIsVisible = true
    },
    mouseLeave: function(){
      this.titleIsVisible = false
    }

  },
  created(){
    // console.log(this.content)
  }


}
</script>

<style lang="scss">
@import '@/css/variables';

.RedirectShopCase{
  position: relative;
  // width: calc(100vw / 9 * 1 );
  height: 100%;

  &--bg{
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: var(--shop-color-lighter); 
    background-color: var(--shop-bg-color); 
  }

  &--img-container{
    position: absolute;
    width: 101%;
    height: 100%;
    top: 0;
    left: 0;

    &--img{
       width: 100%;
       height: 100%;
       object-fit: cover;
    }
  }

  &--text-container{
    position: absolute;
    top: 0;
    left: 0;

    // background-color: var(--shop-color-lighter); 
    background-color: var(--shop-bg-color); 

    font-size: var(--shop-font-redirect);

    overflow-wrap: anywhere;

    @supports ( hyphens: auto ) {
      hyphens: auto;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;


    width: 100%;
    height: 100%;

    & p {
      padding-left: var(--esp-small);
      padding-right: var(--esp-small);
    }
  }
}



.RedirectShopCaseToggle-enter{
  transition: transform .4s ease;
  transform: translate(0, 100%);
}

.RedirectShopCaseToggle-enter-active {
  transition: transform .4s ease;
}

.RedirectShopCaseToggle-enter-to{
  transition: transform .4s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}

// .RedirectShopCaseToggle-enter-cancelled{
//   transition: transform .4s ease;
//   // top: 0vh;
//   transform: translate(0, 0%);
// }

.RedirectShopCaseToggle-leave{
  transition: transform .4s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.RedirectShopCaseToggle-leave-active{
  transition: transform .4s ease;
}

.RedirectShopCaseToggle-leave-to{
  transition: transform .4s ease;
  transform: translate(0, 100%);
}

////////

.RedirectShopCaseToggleInvert-enter{
  transition: transform .4s ease;
  transform: translate(0, -100%);
}

.RedirectShopCaseToggleInvert-enter-active {
  transition: transform .4s ease;
}

.RedirectShopCaseToggleInvert-enter-to{
  transition: transform .4s ease;
  // top: 0vh;
  transform: translate(0, 0);
}

.RedirectShopCaseToggleInvert-enter-cancelled{
  transition: transform .4s ease;
  transform: translate(0, 0);
}

.RedirectShopCaseToggleInvert-leave{
  transition: transform .4s ease;
  // top: 0vh;
  transform: translate(0, 0);
}


.RedirectShopCaseToggleInvert-leave-active{
  transition: transform .4s ease;
}

.RedirectShopCaseToggleInvert-leave-to{
  transition: transform .4s ease;
  transform: translate(0, -100%);
}

.RedirectShopCaseToggleInvert-leave-cancelled{
  transition: transform .4s ease;
  transform: translate(0, -100%);
}

</style>