<template>

	<router-link 
	class="HomepageAnnonceShop"
	:class="'HomepageAnnonceShop-' + clientWidth"
	v-bind:to="whatLinks">

		<div class="HomepageAnnonceShop--img-container" :class="'HomepageAnnonceShop-' + clientWidth">
		<img class="HomepageAnnonceShop--img-container--img" 
		:class="'HomepageAnnonceShop-' + clientWidth"
		v-lazyloading
		:src="content.cover.thumb" 
		:data-srcset="content.cover.srcset" 
		sizes="60vw">
		</div>
		<div class="HomepageAnnonceShop--text-container" :class="'HomepageAnnonceShop-' + clientWidth">
			<div class="HomepageAnnonceShop--text-container--content" :class="'HomepageAnnonceShop-' + clientWidth">
				<div class="HomepageAnnonceShop--text-container--content--title"> {{ content.title }} </div>
				<div class="HomepageAnnonceShop--text-container--content--text" :class="'HomepageAnnonceShop-' + clientWidth"> {{ content.text }} </div>
			</div>	
		</div>

	</router-link >

</template>

<script>

export default {
	name: 'HomepageAnnonceShop',
	props: ['content', 'clientWidth'],
	computed: {

		whatLinks: function(){

			let returnLinks

			if ( this.content.type2 == 'shop' ){
				returnLinks = '/' + this.$route.params.language + '/boutique'

			}else{
				returnLinks = '/' + this.$route.params.language + '/boutique/' + this.content.last_article
			}

			return returnLinks
		},

	},
	created(){
		// console.log(this.content)
	}
}
</script>

<style lang="scss">
@import '@/css/variables';

.HomepageAnnonceShop{
	width: 100%;
	height: var(--home-case-height);
	display: flex;
	z-index: 2;

	background-color: black;

	&--img-container{
		height: 100%;
		width: 50%;

		&--img{
			height: 100%;	
			width: 100%;
			object-fit: cover;	
		}
	}

	&--text-container{
		// margin: var(--esp-med);
		// padding: var(--esp-med);
		width: 50%;
		color: white;

		position: relative;

		&--content{

			padding: var(--esp-med);

			&--title{
				font-size: var(--home-font-case1-title);
				line-height: 0.9;

				// background-color: red;

				overflow-wrap: anywhere;
				
				@supports ( hyphens: auto ) {
					hyphens: auto;
					-webkit-hyphens: auto;
					-moz-hyphens: auto;
					-ms-hyphens: auto;
				}

				line-clamp: 4;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;  
				
				overflow: hidden;
			}

			&--text{
				position: absolute;
				font-size: calc( 0.78 * var(--home-font-case2-title));
				line-height: 0.9;
				overflow-wrap: anywhere;
				padding-bottom: var(--esp-med);
				bottom: 0;

			}

		}
	}
}

.HomepageAnnonceShop.HomepageAnnonceShop-mobileWidth{
	height: auto;
	display: flex;
	flex-wrap: wrap-reverse;
}

.HomepageAnnonceShop--img-container.HomepageAnnonceShop-mobileWidth{
	width: 100%;
	height: auto;
}

.HomepageAnnonceShop--img-container--img.HomepageAnnonceShop-mobileWidth{

}

.HomepageAnnonceShop--text-container.HomepageAnnonceShop-mobileWidth{
	width: 100%;
	// height: 40vw;
}

.HomepageAnnonceShop--text-container--content.HomepageAnnonceShop-mobileWidth{
	padding: var(--esp-big);
}

.HomepageAnnonceShop--text-container--content--text.HomepageAnnonceShop-mobileWidth{
	padding-top: var(--esp-big);
	padding-bottom: 0;
	position: relative;
	font-size: var(--home-font-case1-text);
}
</style>