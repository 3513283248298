class RedirectShopStore {

	constructor () {

		this.state = {
			fr: {
				redirectShop: {}
			},
			en: {
				redirectShop: {}
			},
			data: {
				titleisHiding: false
			}
		}
	}

	pageIsLoaded(langage, page){

		if (Object.keys(this.state.[langage].[page]).length === 0){
			return false
		}else {
			return true
		}	
	}

	setRedirectShop(langage, content){
		if ( Object.keys(this.state.[langage].redirectShop).length === 0 ){
			// console.log('DATASTORE REDIRECTSHOP ADD CONTENT')
			this.state.[langage].redirectShop = content;
		}else{
			// console.log('DATASTORE REDIRECTSHOP CONTENT ALREADY LOADED')
		}
	}

	getRedirectShop(langage){
		return this.state.[langage].redirectShop
	}

	setTitleisHiding(val){
		// console.log('setTitleisHiding store', this.state.data.titleisHiding)
		this.state.data.titleisHiding = val
		// console.log('setTitleisHiding store', this.state.data.titleisHiding)
	}

}


export default {
	init: () => {
		return new RedirectShopStore()
	}
}


